import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import BannerAdd from "./add";
import BannerList from "./list";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class Banner extends React.Component {

    list = (props) => (
        <BannerList {...props} getBanner={this.props.getBanner} banners={this.props.banners} updateBannerStatus={this.props.updateBannerStatus} isRequestingForWhom={this.props.isRequestingForWhom}/>
    );
    add = (props) => (
        <BannerAdd {...props} addBanner={this.props.addBanner} isRequesting={this.props.isRequestingForWhom === 'sale/banner/add'}/>
    );
    update = (props) => (
        <BannerAdd {...props} addBanner={this.props.addBanner} isRequesting={this.props.isRequestingForWhom === 'sale/banner/add'}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.banner.url} render={() => (
                    <Redirect to={ProjectRoutes.banner.list.url }/>
                )}/>
                <Route path={ProjectRoutes.banner.list.url} render={this.list}/>
                <Route path={ProjectRoutes.banner.add.url} render={this.add}/>
                <Route path={ProjectRoutes.banner.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
