import {Slide, toast} from "react-toastify";
import APIEndpoints from "../../../../config/APIEndpoints";
import ProjectRoutes from "../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../utils/HelperFunctions";
import * as ActionTypes from "./actionTypes";
import * as constants from "./constants";
import {USER_NEW_PASSWORD} from "./actionTypes";

export function callOnPageLoad(routerHistory) {
    return (dispatch, getState) => {
        const {userData} = getState()[constants.NAME];
        if (userData && userData.employee_id && userData.status === 'Active') {
            routerHistory.replace({
                pathname: ProjectRoutes.dashboard.url,

            });
        }
    }
}

export function logout(routerHistory) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.USER_LOGIN,
            payload: null
        });
        localStorage.removeItem('user_id');
        routerHistory.push({
            pathname: ProjectRoutes.user.login.url,

        })
    }
}

export function login(email_mobile = '', password = '', routerHistory) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.USER_LOGIN,
            payload: null
        });

        dispatch({
                type: ActionTypes.USER_LOGIN,
                payload: {isRequestingForWhom: 'user/login'},
                promise: APIEndpoints.login({email_mobile, password}),
                callback: (payload) => {
                    if (payload) {
                        if (payload.status === 'Active') {
                            // HelperFunctions.createCookie('user_id', payload.employee_id);
                            localStorage.setItem('user_id', payload.employee_id);
                            routerHistory.push({
                                pathname: ProjectRoutes.dashboard.url,

                            })
                        }
                        if (payload.message) {
                            this.toastId = toast(payload.message, {
                                transition: Slide,
                                closeButton: true,
                                autoClose: 5000,
                                position: toast.POSITION.TOP_CENTER,
                                type: 'error',
                                hideProgressBar: true,
                            })
                        }
                    }
                }
            }
        )
    }
}

export function checkUser() {
    return (dispatch, getState) => {
        dispatch({
                type: ActionTypes.USER_CHECK,
                payload: {isRequestingForWhom: 'user/login'},
                promise: APIEndpoints.checkLogin(),
                callback: (payload) => {
                    if (payload) {
                        if (payload.status === 'Active') {
                            // HelperFunctions.createCookie('user_id', payload.user_id);
                            localStorage.setItem("user_id", payload.employee_id);

                            //set user data to store
                            dispatch({type: ActionTypes.USER_LOGIN, payload});
                        }
                        else {
                            dispatch({type: ActionTypes.USER_LOGIN, payload: null});
                        }
                    }

                }
            }
        )
    }

};

export function forgotPassword(email_mobile = '', routerHistory) {
    return (dispatch, getState) => {
        dispatch({
                type: ActionTypes.USER_FORGOT_PASSWORD,
                payload: {isRequestingForWhom: 'user/forgot-password'},
                promise: APIEndpoints.forgotPassword({email_mobile}),
                callback: (payload) => {
                    if (payload) {
                        if (payload.statusCode === 200) {
                            routerHistory.push({
                                pathname: ProjectRoutes.user.otp.url,
                                state: {email_mobile}
                            })
                        }
                        if (payload.message) {
                            this.toastId = toast(payload.message, {
                                transition: Slide,
                                closeButton: true,
                                autoClose: 5000,
                                position: toast.POSITION.TOP_CENTER,
                                type: payload.statusCode === 200 ? 'success' : 'error',
                                hideProgressBar: true,
                            })
                        }
                    }
                }
            }
        )
    }
}

export function matchOTP(email_mobile = '', otp = '', routerHistory) {
    return (dispatch, getState) => {
        dispatch({
                type: ActionTypes.USER_MATCH_OTP,
                payload: {isRequestingForWhom: 'user/match-otp'},
                promise: APIEndpoints.matchOTP({email_mobile, otp}),
                callback: (payload) => {
                    if (payload) {
                        if (payload.statusCode === 200) {
                            routerHistory.push({
                                pathname: ProjectRoutes.user.newPassword.url,

                                state: {email_mobile}
                            })
                        }
                        if (payload.message) {
                            this.toastId = toast(payload.message, {
                                transition: Slide,
                                closeButton: true,
                                autoClose: 2000,
                                position: toast.POSITION.TOP_CENTER,
                                type: payload.statusCode === 200 ? 'success' : 'error',
                                hideProgressBar: true,
                            })
                        }
                    }
                }
            }
        )
    }
}

export function newPassword(email_mobile = '', password = '', routerHistory) {
    return (dispatch, getState) => {
        dispatch({
                type: ActionTypes.USER_NEW_PASSWORD,
                payload: {isRequestingForWhom: 'user/new-password'},
                promise: APIEndpoints.newPassword({email_mobile, password}),
                callback: (payload) => {
                    console.log(payload);
                    if (payload) {
                        if (payload.statusCode === 200) {
                            routerHistory.push({
                                pathname: ProjectRoutes.user.login.url,
                            })
                        }
                        if (payload.message) {
                            this.toastId = toast(payload.message, {
                                transition: Slide,
                                closeButton: true,
                                autoClose: 2000,
                                position: toast.POSITION.TOP_CENTER,
                                type: payload.statusCode === 200 ? 'success' : 'error',
                                hideProgressBar: true,
                            })
                        }
                    }
                }
            }
        )
    }
}

