const BASE_URL = "/";

const ProjectRoutes = {
    base: {url: BASE_URL},
    user: {
        url: BASE_URL + "user",
        login: {
            url: BASE_URL + "user/login",
        },
        recoverPassword: {
            url: BASE_URL + "user/recover-password",
        },
        otp: {
            url: BASE_URL + "user/otp",
        },
        newPassword: {
            url: BASE_URL + "user/new-password",
        }
    },
    dashboard: {
        url: BASE_URL + "dashboard",
    },
    area: {
        url: BASE_URL + "area",
        country: {
            url: BASE_URL + "area/country",
            list: {
                url: BASE_URL + "area/country/list"
            },
            update: {
                url: BASE_URL + "area/country/update"
            },
            add: {
                url: BASE_URL + "area/country/add"
            }
        },
        state: {
            url: BASE_URL + "area/state",
            list: {
                url: BASE_URL + "area/state/list"
            },
            update: {
                url: BASE_URL + "area/state/update"
            },
            add: {
                url: BASE_URL + "area/state/add"
            }
        },
        city: {
            url: BASE_URL + "area/city",
            list: {
                url: BASE_URL + "area/city/list"
            },
            update: {
                url: BASE_URL + "area/city/update"
            },
            add: {
                url: BASE_URL + "area/city/add"
            }
        }
    },
    employee: {
        url: BASE_URL + "employee",
        list: {
            url: BASE_URL + "employee/list"
        },
        update: {
            url: BASE_URL + "employee/update"
        },
        add: {
            url: BASE_URL + "employee/add"
        },
        profile: {
            url: BASE_URL + "employee/profile"
        }
    },
    designation: {
        url: BASE_URL + "designation",
        list: {
            url: BASE_URL + "designation/list"
        },
        update: {
            url: BASE_URL + "designation/update"
        },
        add: {
            url: BASE_URL + "designation/add"
        }
    },
    department: {
        url: BASE_URL + "department",
        list: {
            url: BASE_URL + "department/list"
        },
        update: {
            url: BASE_URL + "department/update"
        },
        add: {
            url: BASE_URL + "department/add"
        }
    },
    sale: {
        url: BASE_URL + "sale",
        group: {
            url: BASE_URL + "sale/group",
            list: {
                url: BASE_URL + "sale/group/list"
            },
            update: {
                url: BASE_URL + "sale/group/update"
            },
            add: {
                url: BASE_URL + "sale/group/add"
            }
        },
        category: {
            url: BASE_URL + "sale/category",
            list: {
                url: BASE_URL + "sale/category/list"
            },
            update: {
                url: BASE_URL + "sale/category/update"
            },
            add: {
                url: BASE_URL + "sale/category/add"
            }
        },
        productType: {
            url: BASE_URL + "sale/sub-category",
            list: {
                url: BASE_URL + "sale/sub-category/list"
            },
            update: {
                url: BASE_URL + "sale/sub-category/update"
            },
            add: {
                url: BASE_URL + "sale/sub-category/add"
            }
        },
        brand: {
            url: BASE_URL + "sale/brand",
            list: {
                url: BASE_URL + "sale/brand/list"
            },
            update: {
                url: BASE_URL + "sale/brand/update"
            },
            add: {
                url: BASE_URL + "sale/brand/add"
            }
        },
        product: {
            url: BASE_URL + "sale/product",
            list: {
                url: BASE_URL + "sale/product/list"
            },
            update: {
                url: BASE_URL + "sale/product/update"
            },
            add: {
                url: BASE_URL + "sale/product/add"
            }
        },
    },
    banner: {
        url: BASE_URL + "banner",
        list: {
            url: BASE_URL + "banner/list"
        },
        update: {
            url: BASE_URL + "banner/update"
        },
        add: {
            url: BASE_URL + "banner/add"
        }
    },
    rating: {
        url: BASE_URL + "rating",
        list: {
            url: BASE_URL + "rating/list"
        },
        update: {
            url: BASE_URL + "rating/update"
        },
        add: {
            url: BASE_URL + "rating/add"
        }
    },
    contactUs: {
        url: BASE_URL + "contact-us",
        list: {
            url: BASE_URL + "contact-us/list"
        },
        update: {
            url: BASE_URL + "contact-us/update"
        },
        add: {
            url: BASE_URL + "contact-us/add"
        }
    },
    customer: {
        url: BASE_URL + "customer",
        list: {
            url: BASE_URL + "customer/list"
        },
        update: {
            url: BASE_URL + "customer/update"
        },
        add: {
            url: BASE_URL + "customer/add"
        },
        order: {
            url: BASE_URL + "customer/order"
        }
    },
};

export default ProjectRoutes;