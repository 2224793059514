import React from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Button, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import images from "../../../../../assets/images";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class RecoverPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email_mobile: ''
        }
    }

    recoverPas = () => {
        if (!this.props.isRequesting) {
            this.props.forgotPassword(this.state.email_mobile, this.props.history);
        }
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.recoverPas();
        }
    }

    changeEmailMobileHandler = event => {
        this.setState({
            email_mobile: event.target.value
        });
    }

    render() {
        const {forgotPassData, isRequesting} = this.props;
        let isInvalidEmailMob = null;
        if (forgotPassData && forgotPassData.errors) {
            if (forgotPassData.errors.email_mobile) {
                isInvalidEmailMob = forgotPassData.errors.email_mobile;
            }
        }

        return (
            <div className="mx-auto app-login-box col-md-8">
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="HeaderAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <div className="d-flex justify-content-center mb-3">
                        <img src={images.logo} width={100} alt="...logo"/>
                    </div>
                </ReactCSSTransitionGroup>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <div className="modal-dialog w-100 mx-auto">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="h5 modal-title text-center">
                                    <h4 className="mt-2">
                                        <div>Forgot your Password?</div>
                                        <span>Use the form below to recover it.</span>
                                    </h4>
                                </div>
                                <Form onSubmit={(event)=>{event.preventDefault()}}>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <FontAwesomeIcon icon={faUser}/></InputGroupText>
                                            </InputGroupAddon>
                                            <Input invalid={isInvalidEmailMob} type="text" name="email" id="email" placeholder="Email/Mobile here..." value={this.state.email_mobile}
                                                   onChange={this.changeEmailMobileHandler} onKeyDown={this._handleKeyDown}/>
                                            <FormFeedback>{isInvalidEmailMob}</FormFeedback>
                                        </InputGroup>
                                    </FormGroup>
                                </Form>
                                <div className="divider"></div>
                                <h6 className="mb-0">
                                    <Link to={ProjectRoutes.user.login.url} className="text-primary">
                                        Sign in existing account
                                    </Link>
                                </h6>
                            </div>
                            <div className="modal-footer clearfix">
                                <div className="float-right">
                                    <Button color="primary" size={"lg"} onClick={this.recoverPas}>
                                        {isRequesting && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                        Recover Password
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center text-white opacity-8 mt-3">© 2021 Hind Root Care Ltd. All Rights Reserved.</div>
                </ReactCSSTransitionGroup>
            </div>
        )
    }
}
