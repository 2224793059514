import {Slide, toast} from "react-toastify";
import APIEndpoints from "../../../../config/APIEndpoints";
import * as ActionTypes from "./actionTypes";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {

    }
}

export function getBanner(isEmpty = true) {
    return (dispatch, getState) => {
        let payloadObj = {};
        if (isEmpty) {
            payloadObj = {isRequestingForWhom: "sale/banner/list"};
        }
        dispatch({
            type: ActionTypes.GET_BANNER,
            payload: payloadObj,
            promise: APIEndpoints.getBanner(),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function addBanner(data) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.ADD_BANNER,
            payload: {isRequestingForWhom: "sale/banner/add"},
            promise: APIEndpoints.addBanner(data),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateBannerStatus(id = '', status = '', url = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_BANNER_STATUS,
            payload: {isRequestingForWhom: "sale/banner/update-status"},
            promise: APIEndpoints.updateBannerStatus({id, status, url}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getBanner());
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}
