import React from 'react';
import {Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../../../../common/pageTitle";
import {Link} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";

export default class ProductTypeUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.location.state ? this.props.location.state.name : '',
            group_id: this.props.location.state ? this.props.location.state.group_id : '',
            category_id: this.props.location.state ? this.props.location.state.category_id : '',
            image_url: this.props.location.state ? this.props.location.state.image_url : '',
        };

        this.props.getCategory(this.state.group_id);
    }

    groupChange = (event) => {
        this.setState({
            group_id: event.target.value
        });
        this.props.getCategory(event.target.value);
    };

    categoryChange = (event) => {
        this.setState({
            category_id: event.target.value
        });
    };

    onImageChange = (event) => {
        this.setState({
            image_url: event.target.value
        });
    }

    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    };

    add = () => {
        if (!this.props.isRequesting) {
            this.props.addProductType({...this.state, id: this.props.location.state.id});
        }
    };

    render() {
        const {name, group_id, category_id, image_url} = this.state;
        const {isRequesting, groups, categories} = this.props;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Hindroot Sub Categories"
                    subheading="Update a sub category for Hindroot business."
                    icon="pe-7s-albums icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.sale.productType.list.url}>
                            <Button className="btn-shadow mr-3" color="dark">List Sub Categories</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="6" md={{size: 6, offset: 3}}>
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>Update Sub Category</CardTitle>
                                    <Form>
                                        <FormGroup>
                                            <Label for="group">Select Group</Label>
                                            <Input type="select" name="select" id="group" onChange={this.groupChange} value={group_id}>
                                                {groups && groups.length > 0 && groups.map((item, index) => {
                                                    return (
                                                        <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="category">Select Category</Label>
                                            <Input type="select" name="select" id="category" onChange={this.categoryChange} value={category_id}>
                                                {categories && categories.length > 0 && categories.map((item, index) => {
                                                    return (
                                                        <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="image_url">Sub Category Image Url</Label>
                                            <Input type="image_url" name="image_url" id="image_url" placeholder="enter url..." value={image_url} onChange={this.onImageChange}/>
                                            <FormFeedback>Oh noes! pick a correct url for sub category</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="name">Sub Category</Label>
                                            <Input type="text" name="name" id="name" placeholder="pick a sub category..." value={name} onChange={this.onNameChange}/>
                                            <FormFeedback>Oh noes! pick a correct name for sub category</FormFeedback>
                                        </FormGroup>
                                        <Button color="primary" onClick={this.add}>
                                            {isRequesting && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                            {isRequesting ? "Updating..." : "Update"}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
