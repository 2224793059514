import {Slide, toast} from "react-toastify";
import APIEndpoints from "../../../../config/APIEndpoints";
import * as ActionTypes from "./actionTypes";
import HelperFunctions from "../../../../utils/HelperFunctions";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {

    }
}

export function getRating(isEmpty = true, queryData = '', isRequestingForWhom = "sale/rating/list") {
    return (dispatch, getState) => {
        let payloadObj = {};
        if (isEmpty) {
            dispatch({type: ActionTypes.GET_RATING, payload: {ratings: null}});
            payloadObj = {isRequestingForWhom};
        }

        if (queryData) {
            queryData = '?' + HelperFunctions.toURLEncoded(queryData);
        }

        dispatch({
            type: ActionTypes.GET_RATING,
            payload: payloadObj,
            promise: APIEndpoints.getRating(queryData),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function addRating(data) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.ADD_RATING,
            payload: {isRequestingForWhom: "sale/rating/add"},
            promise: APIEndpoints.addRating(data),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        const obj = payload.content && payload.content.errors ? payload.content.errors : payload.message;
                        this.toastId = toast(JSON.stringify(obj), {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateRatingStatus(data, query) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_RATING_STATUS,
            payload: {isRequestingForWhom: "sale/rating/update-status"},
            promise: APIEndpoints.updateRatingStatus(data),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getRating(false, query));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}

export function deleteRating(rating_id = '', query) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.DELETE_RATING,
            payload: {isRequestingForWhom: "sale/rating/delete-rating"},
            promise: APIEndpoints.deleteRating({rating_id: rating_id}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getRating(false, query));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}
