import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import BrandAdd from "./add";
import BrandList from "./list";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import BrandUpdate from "./update";

export default class Brand extends React.Component {

    list = (props) => (
        <BrandList {...props} getBrand={this.props.getBrand} brands={this.props.brands} updateBrandStatus={this.props.updateBrandStatus} isRequestingForWhom={this.props.isRequestingForWhom}/>
    );
    add = (props) => (
        <BrandAdd {...props} addBrand={this.props.addBrand} isRequesting={this.props.isRequestingForWhom === 'sale/brand/add'}/>
    );
    update = (props) => (
        <BrandAdd {...props} addBrand={this.props.addBrand} isRequesting={this.props.isRequestingForWhom === 'sale/brand/add'}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.sale.brand.url} render={() => (
                    <Redirect to={ProjectRoutes.sale.brand.list.url }/>
                )}/>
                <Route path={ProjectRoutes.sale.brand.list.url} render={this.list}/>
                <Route path={ProjectRoutes.sale.brand.add.url} render={this.add}/>
                <Route path={ProjectRoutes.sale.brand.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
