import {Slide, toast} from "react-toastify";
import APIEndpoints from "../../../../config/APIEndpoints";
import * as ActionTypes from "./actionTypes";
import HelperFunctions from "../../../../utils/HelperFunctions";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {

    }
}

export function getContactUs(isEmpty = true, queryData = '', isRequestingForWhom = "sale/contactUs/list") {
    return (dispatch, getState) => {
        let payloadObj = {};
        if (isEmpty) {
            dispatch({type: ActionTypes.GET_CONTACTUS, payload: {contactUss: null}});
            payloadObj = {isRequestingForWhom};
        }

        if (queryData) {
            queryData = '?' + HelperFunctions.toURLEncoded(queryData);
        }

        dispatch({
            type: ActionTypes.GET_CONTACTUS,
            payload: payloadObj,
            promise: APIEndpoints.getContactUs(queryData),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateContactUsStatus(data, query) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_CONTACTUS_STATUS,
            payload: {isRequestingForWhom: "sale/contactUs/update-status"},
            promise: APIEndpoints.updateContactUsStatus(data),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getContactUs(false, query));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}

export function deleteContactUs(contactUs_id = '', query) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.DELETE_CONTACTUS,
            payload: {isRequestingForWhom: "sale/contactUs/delete-contactUs"},
            promise: APIEndpoints.deleteContactUs({contactUs_id: contactUs_id}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getContactUs(false, query));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}
