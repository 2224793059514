import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import ContactUsList from "./list";

export default class ContactUs extends React.Component {

    list = (props) => (
        <ContactUsList {...props}
                       getContactUs={this.props.getContactUs} deleteContactUs={this.props.deleteContactUs}
                       contactUss={this.props.contactUss} totalCount={this.props.totalCount}
                       updateContactUsStatus={this.props.updateContactUsStatus}
                       isRequestingForWhom={this.props.isRequestingForWhom}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.contactUs.url} render={() => (
                    <Redirect to={ProjectRoutes.contactUs.list.url}/>
                )}/>
                <Route path={ProjectRoutes.contactUs.list.url} render={this.list}/>
            </Fragment>
        )
    }
}
