import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Form, FormGroup, Modal, ModalFooter, ModalHeader} from "react-bootstrap";
import {Button, Card, CardBody, CardTitle, Col, Input, Label, ModalBody, Row, Table, UncontrolledTooltip} from "reactstrap";
import Constants from "../../../../../utils/Constants";
import DateHelper from "../../../../../utils/DateHelper";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import PageTitle from "../../../common/pageTitle";
import TrackingIDModel from "./trackingIDModal";
import InvoiceButton from "./invoiceButton";

export default class Orders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: '',
                order_id: '',
                total: '',
                total_type: '=',
                order_status: '',
                country_id: '',
                state_id: '',
                city_id: '',
            },
            currentPage: 1,
            isTrackingIDModelOpen: false,
        }
    }

    componentDidMount() {
        this.props.getOrders({page_size: Constants.defaultPageSize, page_number: this.state.currentPage});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.orders && prevProps.orders !== this.props.orders && this.props.orders.length > 0) {
            window.userCollapse('orders-collapsible');
        }
    }

    componentWillUnmount() {
        window.userCollapseRemove('orders-collapsible');
    }

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };

    countryChange = (event) => {
        this.onInputChange("country_id", event);
        this.props.getState(event.target.value);
    };

    stateChange = (event) => {
        this.onInputChange("state_id", event);
        this.props.getCity(this.state.data.country_id, event.target.value);
    };

    statusChange = (order_id, event) => {
        this.props.updateOrderStatus(order_id, event.target.value, {
            ...this.state.data,
            page_size: Constants.defaultPageSize,
            page_number: this.state.currentPage
        });
    };

    reset = () => {
        this.setState({
            data: {
                name: '',
                order_id: '',
                total: '',
                total_type: '=',
                order_status: '',
                country_id: '',
                state_id: '',
                city_id: '',
            }
        });
    };

    clickView = (order_id) => {
        const btn = document.getElementById('btn' + order_id);
        if (btn)
            btn.click();
    };

    search = () => {
        if (!(this.props.isRequestingForWhom === "customer/order/search")) {
            this.props.getOrders({
                ...this.state.data,
                page_size: Constants.defaultPageSize,
                page_number: 1
            }, "customer/order/search");

            this.setState({currentPage: 1});
        }
    };

    paginationClick = (pageNum) => {

        this.props.getOrders({...this.state.data, page_size: Constants.defaultPageSize, page_number: pageNum});

        this.setState({currentPage: pageNum});
    };

    toggleTrackModel = () => {
        this.setState({
            isTrackingIDModelOpen: !this.state.isTrackingIDModelOpen
        });
    };

    trackModel = () => {
        return (
            <Modal isOpen={this.state.isTrackingIDModelOpen} toggle={this.toggleTrackModel}>
                <ModalHeader toggle={this.toggleTrackModel}>Modal title</ModalHeader>
                <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                    non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </ModalBody>
                <ModalFooter>
                    <Button color="link" onClick={this.toggleTrackModel}>Cancel</Button>
                    <Button color="primary" onClick={this.toggle}>Update Tracking ID</Button>{' '}
                </ModalFooter>
            </Modal>
        );
    }

    renderFilter = () => {
        const {isRequestingForWhom, countries, states, cities} = this.props;
        const {data} = this.state;
        return (
            <React.Fragment>
                <button className="collapsible orders-collapsible py-2 text-muted">Looking for something? <span
                    className="text-success">Click here</span> to refine your search.
                </button>
                <div className="content bg-light mb-3">
                    <Form>
                        <Row form className="pt-2">
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="order_id">Order #ID</Label>
                                    <Input type="text" name="order_id" id="order_id" bsSize="sm"
                                           value={data["order_id"]}
                                           onChange={this.onInputChange.bind(this, "order_id")}/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="name">Name</Label>
                                    <Input type="text" name="name" id="name" bsSize="sm" value={data["name"]}
                                           onChange={this.onInputChange.bind(this, "name")}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="total">Order Total</Label>
                                    <Row>
                                        <Col md={4} className="pr-0">
                                            <Input type="select" name="total_type" id="total_type" bsSize="sm"
                                                   value={data["total_type"]}
                                                   onChange={this.onInputChange.bind(this, "total_type")}>
                                                <option value="=">{'= equal to'}</option>
                                                <option value=">">{'> More Than'}</option>
                                                <option value="<">{'< Less Than'}</option>
                                            </Input>
                                        </Col>
                                        <Col md={8} className="pl-0">
                                            <Input type="text" name="total" id="total" bsSize="sm" value={data["total"]}
                                                   onChange={this.onInputChange.bind(this, "total")}/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <Label for="order_status">Order Status</Label>
                                    <Input type="select" bsSize="sm" name="order_status" id="order_status"
                                           value={data["order_status"]}
                                           onChange={this.onInputChange.bind(this, 'order_status')}>
                                        <option value="">{'--Select Order Status To Filter--'}</option>
                                        <option value="Processing">{'Processing'}</option>
                                        <option value="Pending payment">{'Pending payment'}</option>
                                        <option value="On hold">{'On hold'}</option>
                                        <option value="Completed">{'Completed'}</option>
                                        <option value="Cancelled">{'Cancelled'}</option>
                                        <option value="Refunded">{'Refunded'}</option>
                                        <option value="Failed">{'Failed'}</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="country_id">Select Country</Label>
                                    <Input type="select" bsSize="sm" name="country_id" id="country_id"
                                           value={data["country_id"]} onChange={this.countryChange}>
                                        {countries && countries.length > 0 && countries.map((item, index) => {
                                            return (
                                                <option key={"" + index}
                                                        value={item.id ? item.id : ''}>{item.name}</option>
                                            )
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="state_id">Select State</Label>
                                    <Input type="select" bsSize="sm" name="state_id" id="state_id"
                                           value={data["state_id"]} onChange={this.stateChange}>
                                        {states && states.length > 0 && states.map((item, index) => {
                                            return (
                                                <option key={"" + index}
                                                        value={item.id ? item.id : ''}>{item.name}</option>
                                            )
                                        })}
                                    </Input>
                                    {isRequestingForWhom === 'state/list' && <div
                                        className="d-flex justify-content-center align-items-center loader-absolute background-none">
                                        <div className="spinner-border spinner-border-sm text-primary mt-4"
                                             role="status"/>
                                    </div>}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="city_id">Select City</Label>
                                    <Input type="select" bsSize="sm" name="city_id" id="city_id" value={data["city_id"]}
                                           onChange={this.onInputChange.bind(this, 'city_id')}>
                                        {cities && cities.length > 0 && cities.map((item, index) => {
                                            return (
                                                <option key={"" + index}
                                                        value={item.id ? item.id : ''}>{item.name}</option>
                                            )
                                        })}
                                    </Input>
                                    {isRequestingForWhom === 'city/list' && <div
                                        className="d-flex justify-content-center align-items-center loader-absolute background-none">
                                        <div className="spinner-border spinner-border-sm text-primary mt-4"
                                             role="status"/>
                                    </div>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button color="primary" onClick={this.search} className="mb-3">
                            {(this.props.isRequestingForWhom === "customer/order/search") &&
                            <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                            {(this.props.isRequestingForWhom === "customer/order/search") ? "Searching..." : "Search"}
                        </Button>
                        <Button color="secondary" onClick={this.reset} className="mb-3 ml-2">
                            Reset
                        </Button>
                    </Form>
                </div>
            </React.Fragment>
        );
    };

    renderPagination = () => {
        const {totalOrderCount} = this.props;
        const {currentPage} = this.state;
        if (totalOrderCount > Constants.defaultPageSize) {
            const numPages = Math.ceil(totalOrderCount / Constants.defaultPageSize);

            const pages = [];
            for (let i = 0; i < numPages; i++) {
                pages.push(<li key={"pg-" + i} className={`page-item mx-1 ${currentPage === (i + 1) ? "active" : ""}`}
                               onClick={this.paginationClick.bind(this, (i + 1))}><a
                    className="page-link py-1">{i + 1}</a></li>);
            }

            const isPrevDisabled = currentPage === 1;
            const isNextDisabled = currentPage === numPages;

            return (
                <nav className="pt-5" aria-label="Page navigation">
                    <ul className="pagination flex-wrap justify-content-center justify-content-lg-end mb-0">
                        <li className={`page-item mx-1 ${isPrevDisabled && "disabled"}`}><a className="page-link py-1"
                                                                                            onClick={this.paginationClick.bind(this, (currentPage - 1))}
                                                                                            aria-label="Previous"><span
                            aria-hidden="true">«</span></a></li>
                        {pages}
                        <li className={`page-item mx-1 ${isNextDisabled && "disabled"}`}><a className="page-link py-1"
                                                                                            onClick={this.paginationClick.bind(this, (currentPage + 1))}
                                                                                            aria-label="Next"><span
                            aria-hidden="true">»</span></a></li>
                    </ul>
                </nav>
            );
        }
        else return null;
    };

    render() {
        const {orders, isRequestingForWhom, updateCustomerStatus, updateOrderTrackingId} = this.props;

        const ordersLength = orders ? orders.length : null;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Hindroot Customer"
                    subheading="All orders for Hindroot."
                    icon="pe-7s-cart icon-gradient bg-happy-itmeo"
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>Customer Orders List</CardTitle>
                                    {this.renderFilter()}

                                    {ordersLength > 0 && (
                                        <React.Fragment>
                                            <Table responsive className="mb-0" id="pdftable">
                                                <thead>
                                                <tr>
                                                    <th>Order #</th>
                                                    <th>Name</th>
                                                    <th>Date</th>
                                                    <th>Total</th>
                                                    <th>Ship To</th>
                                                    <th>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {orders.map((order, index) => {
                                                    const obj = {};
                                                    obj.icon = faEye;
                                                    obj.ttxt = "View Order";
                                                    obj.color = "success";

                                                    return (
                                                        <React.Fragment key={"" + index}>
                                                            <tr key={"" + index}>
                                                                <td>
                                                                    <span className="mark">{order.order_id + ' '}</span>
                                                                    <br/>
                                                                    <Input type="select"
                                                                           className={`font-size-sm pl-0 ${order.status_class}`}
                                                                           name="order_status_pre"
                                                                           id="order_status_pre" bsSize="sm"
                                                                           value={order.status}
                                                                           onChange={this.statusChange.bind(this, order.id)}>
                                                                        <option
                                                                            value="Processing">{'Processing'}</option>
                                                                        <option
                                                                            value="Pending payment">{'Pending payment'}</option>
                                                                        <option value="On hold">{'On hold'}</option>
                                                                        <option value="Completed">{'Completed'}</option>
                                                                        <option value="Cancelled">{'Cancelled'}</option>
                                                                        <option value="Refunded">{'Refunded'}</option>
                                                                        <option value="Failed">{'Failed'}</option>
                                                                    </Input>
                                                                </td>
                                                                <td>
                                                                    {order.name}
                                                                </td>
                                                                <td>{DateHelper.getDisplayDateFromStamp(order.order_date)}</td>
                                                                <td><p className="mb-0">
                                                                    {'₹' + order.total}
                                                                    <span
                                                                        className="text-success small">{' for ' + order.order_items.length + ' items'}</span>
                                                                </p></td>
                                                                <td>
                                                                    <p className="text-muted">
                                                                        {order.address.address},<br/>
                                                                        {order.address.city_name + ' - ' + order.address.pin_code + ', ' + order.address.state_name + ', ' + order.address.country_name}
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <TrackingIDModel idKey={"" + index} orderId={order.id} trackingId={order.order_tracking_id}
                                                                                     isRequestingForWhom={isRequestingForWhom}
                                                                                     updateOrderTrackingId={updateOrderTrackingId}/>
                                                                    <Button id={"btn" + index}
                                                                            className="btn-shadow mb-2 mr-2 btn-sm"
                                                                            color={obj.color}
                                                                            onClick={this.clickView.bind(this, order.order_id)}>
                                                                        <FontAwesomeIcon icon={obj.icon} size={"1x"}/>
                                                                        <UncontrolledTooltip placement="left"
                                                                                             target={"btn" + index}>
                                                                            {obj.ttxt}
                                                                        </UncontrolledTooltip>
                                                                    </Button>
                                                                    <InvoiceButton idKey={"" + index} order={order}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={6} className="p-0">
                                                                    <button id={'btn' + order.order_id}
                                                                            className="collapsible orders-collapsible hidden"/>
                                                                    <div
                                                                        className="content text-small text-muted bg-light">
                                                                        <p className="border-bottom">
                                                                            <strong>Order details</strong>
                                                                            {' '}(Order <span
                                                                            className="mark">#{order.order_id}</span>
                                                                            was placed
                                                                            on {DateHelper.getDisplayDateFromStamp(order.order_date)}
                                                                            and is
                                                                            currently <span
                                                                            className="mark">{order.status}</span>)</p>
                                                                        <table className="table table-outer">
                                                                            <thead>
                                                                            <tr>
                                                                                <th>Product</th>
                                                                                <th>Total</th>
                                                                            </tr>
                                                                            </thead>

                                                                            <tbody>
                                                                            {order.order_items.length > 0 && order.order_items.map((item, index) => {
                                                                                const itemCost = parseInt(item.rent_per_product - item.rent_per_product * item.discount / 100) * item.quantity;
                                                                                return (
                                                                                    <tr key={"" + index}>
                                                                                        <td>
                                                                                            <span
                                                                                                className="text-info">{item.name}</span>
                                                                                            <strong>{' × ' + item.quantity}</strong>
                                                                                        </td>
                                                                                        <td>
                                                                <span>
                                                                    <span
                                                                        className="text-decoration-line-through text-muted">{'₹' + item.rent_per_product * item.quantity}</span>
                                                                    {'    ₹' + itemCost}
                                                                    <span
                                                                        className="text-success small">{' (' + item.discount + '% off)'}</span>
                                                                </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            }
                                                                            </tbody>

                                                                            <tfoot>
                                                                            <tr>
                                                                                <th scope="row">Subtotal:</th>
                                                                                <td>
                                                                                    {'    ₹' + order.total}
                                                                                    <span
                                                                                        className="text-success small">{' (saved ₹' + (order.total_original_cost - order.total + ')')}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">Shipping:</th>
                                                                                <td>{(order.shipping_charges === 0) ? '₹0 (Free shipping)' : order.shipping_charges}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">Payment method:</th>
                                                                                <td className="fst-italic">{order.payment_method}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">Total:</th>
                                                                                <td>
                                                                                    <span><span>₹</span>{order.total + order.shipping_charges}</span>
                                                                                    <small>{' (includes 5% IGST)'}</small>
                                                                                </td>
                                                                            </tr>
                                                                            </tfoot>
                                                                        </table>

                                                                        <p className="border-bottom"><strong>Address
                                                                            details</strong></p>
                                                                        <p className="text-muted">
                                                                            {order.name},<br/>
                                                                            {order.address.address},<br/>
                                                                            {order.address.city_name + ' - ' + order.address.pin_code + ', ' + order.address.state_name + ', ' + order.address.country_name}
                                                                        </p>
                                                                        <p className="border-bottom">
                                                                            <strong>Delivery Tracking Id: </strong>
                                                                            {order.order_tracking_id}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })}
                                                </tbody>
                                            </Table>
                                            {this.renderPagination()}
                                        </React.Fragment>
                                    )}
                                </CardBody>
                                {ordersLength === 0 && (
                                    <div className="page-title-subheading d-flex justify-content-center py-5">No Orders
                                        found for selected filters!</div>
                                )}
                                {ordersLength === null && (
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                                        </div>
                                    </div>
                                )}
                                {isRequestingForWhom === "customer/order/update-status" &&
                                <div className="d-flex justify-content-center align-items-center loader-absolute">
                                    <div className="spinner-border mt-4 mb-4 text-primary" role="status">
                                    </div>
                                </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
                {this.trackModel()}
            </React.Fragment>
        );
    }
}
