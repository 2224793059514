import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Button, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {Slide, toast} from "react-toastify";
import images from "../../../../../assets/images";

export default class NewPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            cpassword: ''
        }
    }

    newPassword = () => {

        const {password, cpassword} = this.state;

        if (password === cpassword) {
            if (!this.props.isRequesting) {
                const email_mobile = this.props.location.state ? this.props.location.state.email_mobile : '';
                this.props.newPassword(email_mobile, this.state.password, this.props.history);
            }
        }
        else {
            this.toastId = toast("Password and confirm pass do not match.", {
                transition: Slide,
                closeButton: true,
                autoClose: 2000,
                position: toast.POSITION.TOP_CENTER,
                type: 'error',
                hideProgressBar: true,
            })
        }
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.newPassword();
        }
    }

    changePassHandler = event => {
        this.setState({
            password: event.target.value
        });
    }
    changeCPassHandler = event => {
        this.setState({
            cpassword: event.target.value
        });
    }

    render() {
        const {newPasswordData, isRequesting} = this.props;
        let isInvalidPass = null;
        if (newPasswordData && newPasswordData.errors) {
            if (newPasswordData.errors.password) {
                isInvalidPass = newPasswordData.errors.password;
            }
        }
        return (
            <div className="mx-auto app-login-box col-md-8">
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="HeaderAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <div className="d-flex justify-content-center mb-3">
                        <img src={images.logo} width={100} alt="...logo"/>
                    </div>
                </ReactCSSTransitionGroup>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <div className="modal-dialog w-100 mx-auto">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="h5 modal-title text-center">
                                    <h4 className="mt-2">
                                        <div>Set New Password</div>
                                        <span>Use form below to set new password!</span>
                                    </h4>
                                </div>
                                <Form onSubmit={(event)=>{event.preventDefault()}}>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <FontAwesomeIcon icon={faLock}/></InputGroupText>
                                            </InputGroupAddon>
                                            <Input invalid={!!isInvalidPass} type="text" name="pass" id="pass" type="password"
                                                   placeholder="New Password here..." value={this.state.password}
                                                   onChange={this.changePassHandler}/>
                                            <FormFeedback>{isInvalidPass}</FormFeedback>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <FontAwesomeIcon icon={faLock}/></InputGroupText>
                                            </InputGroupAddon>
                                            <Input invalid={false} type="text" name="cpass" id="cpass"
                                                   placeholder="Confirm New Password here..."
                                                   type="password"
                                                   value={this.state.cpassword}
                                                   onChange={this.changeCPassHandler} onKeyDown={this._handleKeyDown}/>
                                        </InputGroup>
                                    </FormGroup>
                                </Form>
                            </div>
                            <div className="modal-footer clearfix">
                                <div className="float-right">
                                    <Button color="primary" size={"lg"} onClick={this.newPassword}>
                                        {isRequesting &&
                                        <span className="spinner-grow spinner-grow-sm mr-1" role="status"
                                              aria-hidden="true"/>}
                                        Set New Password
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center text-white opacity-8 mt-3">© 2021 Hind Root Care Ltd. All Rights Reserved.</div>
                </ReactCSSTransitionGroup>
            </div>
        )
    }
}
