import * as ActionTypes from "./actionTypes";

const initialState = {
    contactUss: null,
    totalCount: 0
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_CONTACTUS:
            return {...state, ...action.payload};
        default:
            return {...state}
    }
};
export default reducer;