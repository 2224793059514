import HelperFunctions from "../../utils/HelperFunctions";
/*
 * This is a middle ware it gets in action when an action will
 * call a web service so basically this is a mid state somewhere action creator and reducer
 * */
import common from "../modules/common";
import city from "../modules/screens/area/city";
import country from "../modules/screens/area/country";
import state from "../modules/screens/area/state";
import customer from "../modules/screens/customer";
import dashboard from "../modules/screens/dashboard";
import department from "../modules/screens/department";
import designation from "../modules/screens/designation";
import employee from "../modules/screens/employee";
import category from "../modules/screens/sale/category";
import group from "../modules/screens/sale/group";
import brand from "../modules/screens/sale/brand";
import banner from "../modules/screens/banner";
import product from "../modules/screens/sale/product";
import productType from "../modules/screens/sale/productType";
import user from "../modules/screens/user";
import rating from "../modules/screens/rating";
import contactUs from "../modules/screens/contactUs";

export default function fetchMiddleware() {
    return next => action => {
        const {promise, ...rest} = action;
        if (!promise) {
            return next(action);
        }

        // if (!action.disableGlobalLoader)
        if (rest.payload && rest.payload.isRequestingForWhom)
            next({...rest, type: common.actionTypes.REQUEST});

        // HelperFunctions.log(promise.url);
        let body = null;
        if (promise.method === 'GET') {
            body = {method: promise.method, headers: promise.headers}
        } else {
            body = {method: promise.method, headers: promise.headers, body: promise.data};
        }

        const promiseNew = fetch(promise.url, body);
        promiseNew.then(response => response.json())
            .then(response => {
                //if status code in resposde is 401 redirect user to identity sso
                if (response.statusCode === 401) {
                    next({
                        type: common.actionTypes.REQUEST_FAILURE,
                        payload: {err: {code: response.statusCode, message: response.message}}
                    });

                    if (response.statusCode === 401) {
                        console.log('user is not authenticated');
                        HelperFunctions.postMessageToGame({user_unauthenticated: true});
                    }
                } else { //store user info from every API call
                    if (response && response.content && response.content.user_info) {
                        // next(common.actions.storeUserInfo(response.content.user_info));
                    }
                }

                // if (!action.disableGlobalLoader)
                if (rest.payload && rest.payload.isRequestingForWhom)
                    next({...rest, type: common.actionTypes.REQUEST_COMPLETE});

                const payload = parseResponse(action.type, response, rest);
                next({...rest, payload, type: action.type});
                if (action.callback)
                    action.callback(payload);
            })
            .catch(error => {

                if (action.errorCallback) {
                    action.errorCallback(error);
                }

                next({type: common.actionTypes.REQUEST_FAILURE, payload: {err: error}})
            });

        return promiseNew;
    };

    function parseResponse(actionType, response, rest) {
        switch (actionType) {
            case user.actionTypes.USER_LOGIN:
            case user.actionTypes.USER_CHECK:
            case user.actionTypes.USER_FORGOT_PASSWORD:
            case user.actionTypes.USER_MATCH_OTP:
            case user.actionTypes.USER_NEW_PASSWORD:
            case country.actionTypes.GET_COUNTRY:
            case state.actionTypes.GET_STATE:
            case city.actionTypes.GET_CITY:
            case designation.actionTypes.GET_DESIGNATION:
            case group.actionTypes.GET_GROUP:
            case brand.actionTypes.GET_BRAND:
            case banner.actionTypes.GET_BANNER:
            case category.actionTypes.GET_CATEGORY:
            case productType.actionTypes.GET_PRODUCT_TYPE:
            case department.actionTypes.GET_DEPARTMENT:
            case employee.actionTypes.GET_EMPLOYEE:
            case employee.actionTypes.GET_EMPLOYEE_ONE:
            case customer.actionTypes.GET_CUSTOMER_ONE:
            case dashboard.actionTypes.GET_DASH_STATS:
                return response.content ? response.content : response;
            case employee.actionTypes.ADD_EMPLOYEE_BASIC_INFO:
                return parseEmployeeData(response);
            case product.actionTypes.ADD_PRODUCT:
                return parseProductData(response);
            case employee.actionTypes.UPDATE_EMPLOYEE_ADDRESS:
            case employee.actionTypes.UPDATE_EMPLOYEE_EMERGENCY_CONTACT:
            case employee.actionTypes.UPDATE_EMPLOYEE_IMAGE:
            case employee.actionTypes.UPDATE_EMPLOYEE_PERMISSSIONS:
            case employee.actionTypes.UPDATE_EMPLOYEE_DOCUMENTS:
                return parseEmployeeData(response);
            case product.actionTypes.GET_PRODUCT:
                return products(response);
            case rating.actionTypes.GET_RATING:
                return ratings(response);
            case contactUs.actionTypes.GET_CONTACTUS:
                return contactUss(response);
            case customer.actionTypes.GET_CUSTOMER:
                return customers(response);
            case customer.actionTypes.GET_ORDER:
                return order(response);
            default:
                return response;
        }
    }

    function parseEmployeeData(response) {
        if (response.statusCode === 200) {
            var obj = response.content;
            obj.message = response.message;
            obj.statusCode = response.statusCode;
            return obj;
        } else {
            return response.content ? response.content : response;
        }
    }

    function parseProductData(response) {
        if (response.statusCode === 200) {
            var obj = response.content;
            obj.message = response.message;
            obj.statusCode = response.statusCode;
            return obj;
        } else {
            return response.content ? response.content : response;
        }
    }

    function products(response) {
        let content = response.content ? response.content.products : response;
        if (content && content.length > 0) {
            for (let i = 0; i < content.length; i++) {
                let badge_text = "", badge_class = "";
                if (content[i].is_new) {
                    badge_text = "New Arrival";
                    badge_class = "bg-primary";
                }
                if (content[i].is_trending) {
                    badge_text = "Trending";
                    badge_class = "bg-info";
                }
                if (content[i].total_quantity <= 0) {
                    badge_text = "Soldout";
                    badge_class = "bg-danger";
                }
                content[i]["badge_text"] = badge_text;
                content[i]["badge_class"] = badge_class;

                let total = 0;
                for (let j = 0; j < content[i].ratings.length; j++) {
                    total += content[i].ratings[j].rating;
                }
                if (content[i].ratings.length > 0) {
                    content[i]["avgRating"] = (total / content[i].ratings.length).toFixed(1);
                    content[i]["numOfReviews"] = content[i].ratings.length;
                }
            }
            return {totalProductCount: response.content.count, products: content};
        }
        return {totalProductCount: 0, products: []}
    }

    function ratings(response) {
        let content = response.content ? response.content.ratings : response;
        if (content && content.length > 0) {
            return {totalCount: response.content.count, ratings: content};
        }
        return {totalCount: 0, ratings: response}
    }

    function contactUss(response) {
        let content = response.content ? response.content.data : response;
        if (content && content.length > 0) {
            return {totalCount: response.content.count, contactUss: content};
        }
        return {totalCount: 0, contactUss: response}
    }

    function customers(response) {
        let content = response.content ? response.content.users : response;
        if (content && content.length > 0) {
            return {totalCustomerCount: response.content.count, customers: content};
        }
        return {totalCustomerCount: 0, customers: []}
    }

    function order(response) {
        if (response.statusCode === 200 && response.content && response.content.orders && response.content.orders.length > 0) {
            const orders = response.content.orders;
            for (let i = 0; i < orders.length; i++) {
                const order_items = orders[i].order_items;
                const match_order_items = orders[i].match_order_items;

                let total_original_cost = 0;

                for (let j = 0; j < order_items.length; j++) {
                    order_items[j].name = match_order_items[j].name;
                    order_items[j].image_url1 = match_order_items[j].image_url1;
                    order_items[j].group_id = match_order_items[j].group_id;
                    order_items[j].category_id = match_order_items[j].category_id;
                    order_items[j].product_type_id = match_order_items[j].product_type_id;

                    // const itemCost = parseInt(item.rent_per_product - item.rent_per_product * item.discount / 100) * item.quantity;
                    total_original_cost += parseInt(order_items[j].rent_per_product * order_items[j].quantity)
                }
                let status_class = "bg-info";
                switch (orders[i].status) {
                    case "Processing":
                        status_class = "bg-info";
                        break;
                    case "Pending payment":
                        status_class = "bg-warning";
                        break;
                    case "On hold":
                        status_class = "bg-primary";
                        break;
                    case "Completed":
                        status_class = "bg-success";
                        break;
                    case "Cancelled":
                        status_class = "bg-danger";
                        break;
                    case "Refunded":
                        status_class = "bg-info";
                        break;
                    case "Failed":
                        status_class = "bg-danger";
                        break;
                }
                orders[i].status_class = status_class;
                orders[i].total_original_cost = total_original_cost;
            }
            return {totalOrderCount: response.content.count, orders};
        } else {
            return {totalOrderCount: 0, orders: []};
        }
    }
}