import React from 'react';
import {Button, Card, CardBody, CardTitle, Col, Row, Table, UncontrolledTooltip} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import PageTitle from "../../../common/pageTitle";

export default class DesignationList extends React.Component {

    componentDidMount() {
        this.props.getDesignation();
    }

    render() {
        const {designations, isRequestingForWhom, updateDesignationStatus} = this.props;
        return (
            <React.Fragment>
                <PageTitle
                    heading="Hindroot Designations"
                    subheading="Available designations for Hindroot."
                    icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.designation.add.url }>
                            <Button className="btn-shadow mr-3" color="dark">Add Designation</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>Designation List</CardTitle>
                                    {designations && designations.length > 0 ? (
                                        <Table responsive striped className="mb-0">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {designations.map((item, index) => {
                                                const obj = {};
                                                if (HelperFunctions.isActive(item.status)) {
                                                    obj.icon = faThumbsDown;
                                                    obj.ttxt = "Make Inactive";
                                                    obj.color = "danger";
                                                    obj.statusToChange = "Inactive";
                                                } else {
                                                    obj.icon = faThumbsUp;
                                                    obj.ttxt = "Make Active";
                                                    obj.color = "success";
                                                    obj.statusToChange = "Active";
                                                }
                                                return (
                                                    <tr key={"" + index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.name}</td>
                                                        <td>{item.status}</td>
                                                        <td>
                                                            <Link to={{
                                                                pathname: ProjectRoutes.designation.update.url,

                                                                state: {id: item.id, name: item.name}
                                                            }}>
                                                                <Button className="btn-shadow mr-2 btn-sm" color="info">
                                                                    <FontAwesomeIcon icon={faPen} size={"1x"}/>
                                                                </Button>
                                                            </Link>
                                                            <Button id={"btn" + index} className="btn-shadow mr-2 btn-sm" color={obj.color}
                                                                    onClick={updateDesignationStatus.bind(this, item.id, obj.statusToChange, item.name)}>
                                                                <FontAwesomeIcon icon={obj.icon} size={"1x"}/>
                                                                <UncontrolledTooltip placement="left" target={"btn" + index}>
                                                                    {obj.ttxt}
                                                                </UncontrolledTooltip>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                                            </div>
                                        </div>
                                    )
                                    }
                                </CardBody>
                                {isRequestingForWhom === "designation/update-status" &&
                                <div className="d-flex justify-content-center align-items-center loader-absolute">
                                    <div className="spinner-border mt-4 mb-4 text-primary" role="status">
                                    </div>
                                </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
