import * as ActionTypes from "./actionTypes";
import APIEndpoints from "../../../../config/APIEndpoints";
import {Slide, toast} from "react-toastify";
import country from "../area/country";
import designation from "../designation";
import department from "../department";
import * as constants from "./constants";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {
        dispatch(country.actions.getCountry());
        dispatch(designation.actions.getDesignation());
        dispatch(department.actions.getDepartment());
    }
}

export function reset() {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.GET_EMPLOYEE_ONE, payload: {}});
    }
}

export function getEmployeeById(id = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.GET_EMPLOYEE_ONE,
            payload: {isRequestingForWhom: "employee/single"},
            promise: APIEndpoints.getEmployee(id),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function getEmployee() {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.GET_EMPLOYEE,
            payload: {isRequestingForWhom: "employee/list"},
            promise: APIEndpoints.getEmployee(),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function addEmployeeBasicInfo(employeeData = {}, id = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.ADD_EMPLOYEE_BASIC_INFO,
            payload: {isRequestingForWhom: "employee/add"},
            promise: APIEndpoints.addEmployee(employeeData, id),
            callback: (payload) => {
                if (payload) {
                    if (payload && payload.employee_id) {
                        dispatch(changeTab());
                    }
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}


export function updateEmployeeAddress(data = {}) {
    return (dispatch, getState) => {

        const {employee_id} = getState()[constants.NAME].employeeAddData;

        dispatch({
            type: ActionTypes.UPDATE_EMPLOYEE_ADDRESS,
            payload: {isRequestingForWhom: "employee/address-update"},
            promise: APIEndpoints.updateEmployeeAddress(data, employee_id),
            callback: (payload) => {
                if (payload) {
                    if (payload.statusCode === 200 && payload.current_address) {
                        dispatch(getEmployeeById(employee_id));
                        console.log('Changing tabs');
                        dispatch(changeTab());
                    }
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateEmployeeEmergencyContact(data = {}) {
    return (dispatch, getState) => {
        const {employee_id} = getState()[constants.NAME].employeeAddData;

        dispatch({
            type: ActionTypes.UPDATE_EMPLOYEE_EMERGENCY_CONTACT,
            payload: {isRequestingForWhom: "employee/emergency-contact-update"},
            promise: APIEndpoints.updateEmployeeEmergencycontact(data, employee_id),
            callback: (payload) => {
                if (payload) {
                    if (payload.statusCode === 200 && payload.name) {
                        dispatch(getEmployeeById(employee_id));
                        dispatch(changeTab());
                    }
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateEmployeeImage(data = {}) {
    return (dispatch, getState) => {
        const {employee_id} = getState()[constants.NAME].employeeAddData;
        data.append("employee_id", employee_id);

        dispatch({
            type: ActionTypes.UPDATE_EMPLOYEE_IMAGE,
            payload: {isRequestingForWhom: "employee/image-update"},
            promise: APIEndpoints.updateEmployeeImage(data),
            callback: (payload) => {
                if (payload) {
                    if (payload.statusCode === 200 && payload.image_url) {
                        dispatch(getEmployeeById(employee_id));
                        dispatch(changeTab());
                    }
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateEmployeeDocuments(data = {}) {
    return (dispatch, getState) => {
        const {employee_id} = getState()[constants.NAME].employeeAddData;
        data.append("employee_id", employee_id);

        dispatch({
            type: ActionTypes.UPDATE_EMPLOYEE_DOCUMENTS,
            payload: {isRequestingForWhom: "employee/documents-update"},
            promise: APIEndpoints.updateEmployeeDocuments(data),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        if (payload.statusCode === 200 && payload.aadhar_url) {
                            dispatch(getEmployeeById(employee_id));
                            // dispatch(changeTab());
                        }
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateEmployeeStatus(employee_id = '', status = '') {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_EMPLOYEE_STATUS,
            payload: {isRequestingForWhom: "employee/update-status"},
            promise: APIEndpoints.updateEmployeeStatus({employee_id, status}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getEmployee());
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}

export function changeTab(tabNum = null) {
    console.log('changing tab ', tabNum)
    return (dispatch, getState) => {
        if (tabNum != null) {
            dispatch({type: ActionTypes.CHANGE_TAB, payload: {selectedTabKey: tabNum}});
        } else {
            const {selectedTabKey} = getState()[constants.NAME];
            dispatch({type: ActionTypes.CHANGE_TAB, payload: {selectedTabKey: selectedTabKey + 1}});
        }
    }
}

export function updateEmployeePermissions(data = {}) {
    return (dispatch, getState) => {

        const {employee_id} = getState()[constants.NAME].employeeAddData;

        dispatch({
            type: ActionTypes.UPDATE_EMPLOYEE_PERMISSSIONS,
            payload: {isRequestingForWhom: "employee/permissions-update"},
            promise: APIEndpoints.updateEmployeePermissions(data, employee_id),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}
