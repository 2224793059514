import React, {Component} from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Slide, toast} from "react-toastify";
import {Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Label, ListGroup, ListGroupItem, Row} from "reactstrap";

class Permissions extends Component {
    constructor(props) {
        super(props);
        const permissions = (this.props.employeeAddData && this.props.employeeAddData.permissions) ? this.props.employeeAddData.permissions : {};
        this.state = {
            //area
            area_list: false,
            area_add: false,
            area_edit: false,
            area_active_inactive: false,
            //employee designation
            employee_designation_list: false,
            employee_designation_add: false,
            employee_designation_edit: false,
            employee_designation_active_inactive: false,
            //employee_department
            employee_department_list: false,
            employee_department_add: false,
            employee_department_edit: false,
            employee_department_active_inactive: false,
            //employee
            employee_list: false,
            employee_add: false,
            employee_edit: false,
            employee_active_inactive: false,
            //Shop group
            shop_group_list: false,
            shop_group_add: false,
            shop_group_edit: false,
            shop_group_active_inactive: false,
            //Shop category
            shop_categories_list: false,
            shop_categories_add: false,
            shop_categories_edit: false,
            shop_categories_active_inactive: false,
            //Shop
            shop_list: false,
            shop_add: false,
            shop_edit: false,
            shop_active_inactive: false,
            //Product
            product_list: false,
            product_add: false,
            product_edit: false,
            product_active_inactive: false,
            //Service Type
            service_type_list: false,
            service_type_add: false,
            service_type_edit: false,
            service_type_active_inactive: false,
            //Services
            services_list: false,
            services_add: false,
            services_edit: false,
            services_active_inactive: false,
            //samaj notice
            samaj_notice_list: false,
            samaj_notice_add: false,
            samaj_notice_edit: false,
            samaj_notice_active_inactive: false,
            //App user
            app_user_list: false,
            app_user_add: false,
            app_user_edit: false,
            app_user_active_inactive: false,
            ...permissions
        };
    }

    componentDidMount() {
        if (this.props.employeeAddData && this.props.employeeAddData.employee_id) {
        } else {
            this.props.changeTab(0);
        }
    }

    setPermissions = () => {
        if (!(this.props.isRequestingForWhom === "employee/permissions-update")) {
            this.props.updateEmployeePermissions(this.state);
        }
    };

    changeCheckHandler = (key, event) => {
        this.setState({
            [key]: event.target.checked
        });
    };

    render() {

        const {isRequestingForWhom, employeeImageData, employeeAddData} = this.props;
        const {data} = this.state;
        const errors = employeeImageData && employeeImageData.errors ? employeeImageData.errors : {};

        return (
            <ReactCSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <Row>
                    <Col lg="12">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle>Employee Usage Permissions</CardTitle>
                                <form onSubmit={this.handleUploadImage}>

                                    <Label className="font-weight-bold">Area Module</Label>
                                    <ListGroup className="mb-1">
                                        <ListGroupItem>
                                            <FormGroup row>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'area_list')}checked={this.state.area_list}/>{' '}
                                                            List
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'area_add')}checked={this.state.area_add}/>{' '}
                                                            Add
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'area_edit')}checked={this.state.area_edit}/>{' '}
                                                            Edit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'area_active_inactive')}checked={this.state.area_active_inactive}/>{' '}
                                                            Active/Inactive
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </ListGroupItem>
                                    </ListGroup>

                                    <Label className="font-weight-bold">Employee Module</Label>
                                    <ListGroup className="mb-1">
                                        <ListGroupItem>
                                            <Label className="text-muted font-weight-bold">Designation</Label>
                                            <FormGroup row>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'employee_designation_list')}checked={this.state.employee_designation_list}/>{' '}
                                                            List
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'employee_designation_add')}checked={this.state.employee_designation_add}/>{' '}
                                                            Add
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'employee_designation_edit')}checked={this.state.employee_designation_edit}/>{' '}
                                                            Edit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'employee_designation_active_inactive')}
                                                                  checked={this.state.employee_designation_active_inactive}/>{' '}
                                                            Active/Inactive
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </ListGroupItem>

                                        <ListGroupItem>
                                            <Label className="text-muted font-weight-bold">Department</Label>
                                            <FormGroup row>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'employee_department_list')}checked={this.state.employee_department_list}/>{' '}
                                                            List
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'employee_department_add')}checked={this.state.employee_department_add}/>{' '}
                                                            Add
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'employee_department_edit')}checked={this.state.employee_department_edit}/>{' '}
                                                            Edit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'employee_department_active_inactive')}
                                                                  checked={this.state.employee_department_active_inactive}/>{' '}
                                                            Active/Inactive
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </ListGroupItem>

                                        <ListGroupItem>
                                            <Label className="text-muted font-weight-bold">Employee</Label>
                                            <FormGroup row>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'employee_list')}checked={this.state.employee_list}/>{' '}
                                                            List
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'employee_add')}checked={this.state.employee_add}/>{' '}
                                                            Add
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'employee_edit')}checked={this.state.employee_edit}/>{' '}
                                                            Edit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'employee_active_inactive')}checked={this.state.employee_active_inactive}/>{' '}
                                                            Active/Inactive
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </ListGroupItem>
                                    </ListGroup>

                                    <Label className="font-weight-bold">Shop Module</Label>
                                    <ListGroup className="mb-1">
                                        <ListGroupItem>
                                            <Label className="text-muted font-weight-bold">Group</Label>
                                            <FormGroup row>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'shop_group_list')}checked={this.state.shop_group_list}/>{' '}
                                                            List
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'shop_group_add')}checked={this.state.shop_group_add}/>{' '}
                                                            Add
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'shop_group_edit')}checked={this.state.shop_group_edit}/>{' '}
                                                            Edit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'shop_group_active_inactive')}
                                                                  checked={this.state.shop_group_active_inactive}/>{' '}
                                                            Active/Inactive
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <Label className="text-muted font-weight-bold">Categories</Label>
                                            <FormGroup row>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'shop_categories_list')}checked={this.state.shop_categories_list}/>{' '}
                                                            List
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'shop_categories_add')}checked={this.state.shop_categories_add}/>{' '}
                                                            Add
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'shop_categories_edit')}checked={this.state.shop_categories_edit}/>{' '}
                                                            Edit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'shop_categories_active_inactive')}
                                                                  checked={this.state.shop_categories_active_inactive}/>{' '}
                                                            Active/Inactive
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <Label className="text-muted font-weight-bold">Shop</Label>
                                            <FormGroup row>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'shop_list')}checked={this.state.shop_list}/>{' '}
                                                            List
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'shop_add')}checked={this.state.shop_add}/>{' '}
                                                            Add
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'shop_edit')}checked={this.state.shop_edit}/>{' '}
                                                            Edit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'shop_active_inactive')}
                                                                  checked={this.state.shop_active_inactive}/>{' '}
                                                            Active/Inactive
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <Label className="text-muted font-weight-bold">Product</Label>
                                            <FormGroup row>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'product_list')}checked={this.state.product_list}/>{' '}
                                                            List
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'product_add')}checked={this.state.product_add}/>{' '}
                                                            Add
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'product_edit')}checked={this.state.product_edit}/>{' '}
                                                            Edit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'product_active_inactive')}
                                                                  checked={this.state.product_active_inactive}/>{' '}
                                                            Active/Inactive
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </ListGroupItem>
                                    </ListGroup>

                                    <Label className="font-weight-bold">User Services Module</Label>
                                    <ListGroup className="mb-1">
                                        <ListGroupItem>
                                            <Label className="text-muted font-weight-bold">Service Type</Label>
                                            <FormGroup row>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'service_type_list')}checked={this.state.service_type_list}/>{' '}
                                                            List
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'service_type_add')}checked={this.state.service_type_add}/>{' '}
                                                            Add
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'service_type_edit')}checked={this.state.service_type_edit}/>{' '}
                                                            Edit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'service_type_active_inactive')}checked={this.state.service_type_active_inactive}/>{' '}
                                                            Active/Inactive
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <Label className="text-muted font-weight-bold">Services</Label>
                                            <FormGroup row>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'services_list')}checked={this.state.services_list}/>{' '}
                                                            List
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'services_add')}checked={this.state.services_add}/>{' '}
                                                            Add
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'services_edit')}checked={this.state.services_edit}/>{' '}
                                                            Edit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'services_active_inactive')}checked={this.state.services_active_inactive}/>{' '}
                                                            Active/Inactive
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </ListGroupItem>
                                    </ListGroup>

                                    <Label className="font-weight-bold">Samaj Notice Module</Label>
                                    <ListGroup className="mb-1">
                                        <ListGroupItem>
                                            <Label className="text-muted font-weight-bold">Samaj Notice</Label>
                                            <FormGroup row>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'samaj_notice_list')}checked={this.state.samaj_notice_list}/>{' '}
                                                            List
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'samaj_notice_add')}checked={this.state.samaj_notice_add}/>{' '}
                                                            Add
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'samaj_notice_edit')}checked={this.state.samaj_notice_edit}/>{' '}
                                                            Edit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'samaj_notice_active_inactive')}checked={this.state.samaj_notice_active_inactive}/>{' '}
                                                            Active/Inactive
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </ListGroupItem>
                                    </ListGroup>

                                    <Label className="font-weight-bold">App User Module</Label>
                                    <ListGroup className="mb-1">
                                        <ListGroupItem>
                                            <Label className="text-muted font-weight-bold">App Users</Label>
                                            <FormGroup row>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'app_user_list')}checked={this.state.app_user_list}/>{' '}
                                                            List
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'app_user_add')}checked={this.state.app_user_add}/>{' '}
                                                            Add
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'app_user_edit')}checked={this.state.app_user_edit}/>{' '}
                                                            Edit
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input type="checkbox" onChange={this.changeCheckHandler.bind(this, 'app_user_active_inactive')}checked={this.state.app_user_active_inactive}/>{' '}
                                                            Active/Inactive
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </ListGroupItem>
                                    </ListGroup>

                                    <Button className="mt-2" color="primary" onClick={this.setPermissions}>
                                        {(isRequestingForWhom === "employee/permissions-update") &&
                                        <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                        {(isRequestingForWhom === "employee/permissions-update") ? "Saving..." : "Save"}
                                    </Button>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ReactCSSTransitionGroup>
        );
    }
}

Permissions.propTypes = {};

export default Permissions;