export const MastersNav = [
    {
        icon: 'pe-7s-graph2',
        label: 'Dashboard',
        to: '/dashboard',
        linkId: 1
    },
    {
        icon: 'pe-7s-map-marker',
        label: 'Area Module',
        linkId: 2,
        content: [
            {
                label: 'Country',
                to: '/area/country',
                linkId: 3
            },
            {
                label: 'State',
                to: '/area/state',
                linkId: 4

            },
            {
                label: 'City',
                to: '/area/city',
                linkId: 5
            }
        ],
    },
    {
        icon: 'pe-7s-id',
        label: 'Employee Module',
        linkId: 6,
        content: [
            {
                icon: 'pe-7s-display1',
                label: 'Designation',
                to: '/designation',
                linkId: 7
            },
            {
                icon: 'pe-7s-display2',
                label: 'Department',
                to: '/department',
                linkId: 8
            },
            {
                icon: 'pe-7s-id',
                label: 'Employee',
                to: '/employee',
                linkId: 9
            },
        ]
    },
    {
        icon: 'pe-7s-photo',
        label: 'Site Banners',
        to: '/banner',
        linkId: 10
    },
    {
        icon: 'pe-7s-keypad',
        label: 'Product Categories',
        linkId: 11,
        content: [
            {
                icon: 'pe-7s-display1',
                label: 'Groups',
                to: '/sale/group',
                linkId: 12
            },
            {
                icon: 'pe-7s-server',
                label: 'Categories',
                to: '/sale/category',
                linkId: 13
            },
            {
                icon: 'pe-7s-albums',
                label: 'Sub Categories',
                to: '/sale/sub-category',
                linkId: 14
            },
        ]
    },
    {
        icon: 'pe-7s-target',
        label: 'Product Brands',
        to: '/sale/brand',
        linkId: 15
    },
    {
        icon: 'pe-7s-cart',
        label: 'Products',
        to: '/sale/product',
        linkId: 16
    },
    {
        icon: 'pe-7s-star',
        label: 'Product Ratings & Reviews',
        to: '/rating',
        linkId: 17
    },
    {
        icon: 'pe-7s-headphones',
        label: 'Contact Us Queries',
        to: '/contact-us',
        linkId: 18
    },
    {
        icon: 'pe-7s-users',
        label: 'Customer Module',
        linkId: 19,
        content: [
            {
                icon: 'pe-7s-display1',
                label: 'Customers',
                to: '/customer',
                linkId: 20
            },
            {
                icon: 'pe-7s-display2',
                label: 'Orders',
                to: '/customer/order',
                linkId: 21
            },
        ]
    },
];