import React from 'react';
import {Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../../../../common/pageTitle";
import {Link} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import ImageCrop from "../../../../../../commons/components/ImageCrop";
import images from "../../../../../../assets/images";

export default class BrandAdd extends React.Component {

    constructor(props) {
        super(props);

        this.isUpdate = (props.location.state && props.location.state.brand) ? true : false;
        const obj = this.isUpdate ? this.props.location.state.brand : {};

        this.state = {
            id: '',
            name: '',
            image_url: '',
            file: '',
            selectedImageNumber: null,
            ...obj
        }
    }

    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    }

    add = () => {
        if (!this.props.isRequesting) {
            const data = new FormData();
            data.append("name", this.state.name);
            data.append("image_url", this.state.image_url);
            data.append("file", this.state.file);
            data.append("id", this.state.id);

            this.props.addBrand(data);
        }
    }

    selectFile = (selectedFileInput) => {
        this.setState({selectedImageNumber: selectedFileInput})
    };

    hideImageCrop = () => {
        this.setState({selectedImageNumber: null});
    };

    getCroppedImage = (croppedFileObj) => {
        this.setState({
            file: croppedFileObj.blob,
            image_url: croppedFileObj.croppedImageUrl,
            selectedImageNumber: null
        });
    };

    returnImagePath = (imagePath) => {
        if (imagePath) {
            return imagePath.includes("?v=") ? this.props.location.state.brand.base_url + imagePath : imagePath;
        }
        return images.cameraPhoto;
    };

    render() {
        const {name, image_url, file, selectedImageNumber} = this.state;
        const {isRequesting} = this.props;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Hindroot Categories"
                    subheading={this.isUpdate ? "Update brand for Hindroot business." : "Add a new brand for Hindroot business."}
                    icon="pe-7s-keypad icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.sale.brand.list.url }>
                            <Button className="btn-shadow mr-3" color="dark">List Brand</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="6" md={{size: 6, offset: 3}}>
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>Add Brand</CardTitle>
                                    <Form>
                                        <FormGroup>
                                            <Label for="name">Select Brand Image/Logo</Label>
                                            <div className='border-dark p-3 cursor-pointer' onClick={this.selectFile.bind(this, 1)}>
                                                <img src={this.returnImagePath(image_url)} alt="" width={100}/>
                                                {selectedImageNumber && <ImageCrop getCroppedImage={this.getCroppedImage} hideImageCrop={this.hideImageCrop}/>}
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="name">Brand</Label>
                                            <Input type="text" name="name" id="name" placeholder="pick a brand..." value={name} onChange={this.onNameChange}/>
                                            <FormFeedback>Oh noes! pick a correct name for brand</FormFeedback>
                                        </FormGroup>
                                        <Button color="primary" onClick={this.add}>
                                            {isRequesting && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                            {isRequesting ? this.isUpdate ? "Updating..." :"Adding..." : this.isUpdate ? "Update" : "Add"}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
