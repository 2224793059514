    import * as ActionTypes from "./actionTypes";

const initialState = {
        userData: undefined,
        forgotPassData: null,
        matchOTPData: null,
        newPasswordData: null
    }
;
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.USER_LOGIN:
            return {...state, userData: action.payload};
        case ActionTypes.USER_FORGOT_PASSWORD:
            return {...state, forgotPassData: action.payload};
        case ActionTypes.USER_NEW_PASSWORD:
            return {...state, newPasswordData: action.payload};
        case ActionTypes.USER_MATCH_OTP:
            return {...state, matchOTPData: action.payload};
        default:
            return {...state}
    }
};
export default reducer;