import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import Banner from "./components";
import common from "../../common";

class BannerSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
            <Banner {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    banners: state[constants.NAME].banners,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getBanner: () => dispatch(actions.getBanner()),
        addBanner: (name, id) => dispatch(actions.addBanner(name, id)),
        updateBannerStatus: (id, status, name) => dispatch(actions.updateBannerStatus(id, status, name)),
    }
};

export const BannerContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(BannerSmart));
export default {actionTypes, actions, reducer, constants};