import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import ContactUs from "./components";
import common from "../../common";
import {deleteContactUs} from "./actions";

class ContactUsSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
            <ContactUs {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    contactUss: state[constants.NAME].contactUss,
    totalCount: state[constants.NAME].totalCount,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getContactUs: (isEmpty, queryData, isRequestingForWhom) => dispatch(actions.getContactUs(isEmpty, queryData, isRequestingForWhom)),
        deleteContactUs: (contactUs_id, queryData) => dispatch(actions.deleteContactUs(contactUs_id, queryData)),
        updateContactUsStatus: (data, queryData) => dispatch(actions.updateContactUsStatus(data, queryData)),
    }
};

export const ContactUsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactUsSmart));
export default {actionTypes, actions, reducer, constants};