const Constants = {
    rewardsConfig: {
        confetti: {
            type: 'confetti',
            fakingRequest: false,
            angle: 90,
            decay: 0.91,
            spread: 90,
            startVelocity: 60,
            elementCount: 100,
            elementSize: 12,
            lifetime: 200,
            zIndex: 9999999,
            springAnimation: true
        },
        welcome: {
            type: 'coin',
            fakingRequest: false,
            angle: 90,
            decay: 0.91,
            spread: 50,
            startVelocity: 60,
            elementCount: 20,
            elementSize: 20,
            lifetime: 100,
            zIndex: 10,
            springAnimation: true
        },
    },
    defaultPageSize:30
};

export default Constants;