import * as ActionTypes from "./actionTypes";
import APIEndpoints from "../../../../../config/APIEndpoints";
import {Slide, toast} from "react-toastify";
import group from "../group";

export function callOnPageLoad(current_game_url = '') {
    return (dispatch, getState) => {
        dispatch(group.actions.getGroup());
    }
}

export function getProductType(group_id, category_id, isEmptyFirst = true) {
    return (dispatch, getState) => {

        let payloadObj = {};
        if (isEmptyFirst) {
            dispatch({type: ActionTypes.GET_PRODUCT_TYPE, payload: group_id === '' ? [] : null});
            payloadObj = {isRequestingForWhom: "sale/product-type/list"};
        }

        // if (group_id === '')
        //     return;


        dispatch({
            type: ActionTypes.GET_PRODUCT_TYPE,
            payload: payloadObj,
            promise: APIEndpoints.getProductType(group_id, category_id),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch({type: ActionTypes.GET_PRODUCT_TYPE, payload: []})
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function addProductType(data) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.ADD_PRODUCT_TYPE,
            payload: {isRequestingForWhom: "sale/product-type/add"},
            promise: APIEndpoints.addProductType(data),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        })
                    }
                }
            }
        })
    }
}

export function updateProductTypeStatus({group_id = '', category_id = '', id = '', status = '', name = ''}, filters) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_PRODUCT_TYPE_STATUS,
            payload: {isRequestingForWhom: "sale/product-type/update-status"},
            promise: APIEndpoints.updateProductTypeStatus({id, status, group_id, category_id, name}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getProductType(filters.group_id, filters.category_id, false));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}


export function updateFeaturedProductTypeStatus({group_id = '', category_id = '', id = '', is_featured = false, name = ''}, filters) {
    return (dispatch, getState) => {
        dispatch({
            type: ActionTypes.UPDATE_PRODUCT_TYPE_STATUS,
            payload: {isRequestingForWhom: "sale/product-type/update-status"},
            promise: APIEndpoints.updateFeaturedProductTypeStatus({id, is_featured, group_id, category_id, name}),
            callback: (payload) => {
                if (payload) {
                    if (payload.message) {
                        dispatch(getProductType(filters.group_id, filters.category_id, false));
                        this.toastId = toast(payload.message, {
                            transition: Slide,
                            closeButton: true,
                            autoClose: 5000,
                            position: toast.POSITION.TOP_CENTER,
                            type: payload.statusCode === 200 ? 'success' : 'error',
                            hideProgressBar: true,
                        });
                    }
                }
            }
        })
    }
}
