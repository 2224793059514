import React from "react";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import {withRouter} from "react-router-dom";

class PermissionChecker extends React.PureComponent {

    componentDidMount() {
        const {
            area_list,
            area_add,
            area_edit,
            area_active_inactive,
            //employee designation
            employee_designation_list,
            employee_designation_add,
            employee_designation_edit,
            employee_designation_active_inactive,
            //employee_department
            employee_department_list,
            employee_department_add,
            employee_department_edit,
            employee_department_active_inactive,
            //employee
            employee_list,
            employee_add,
            employee_edit,
            employee_active_inactive,
            //Shop group
            shop_group_list,
            shop_group_add,
            shop_group_edit,
            shop_group_active_inactive,
            //Shop category
            shop_categories_list,
            shop_categories_add,
            shop_categories_edit,
            shop_categories_active_inactive,
            //Shop
            shop_list,
            shop_add,
            shop_edit,
            shop_active_inactive,
            //Product
            product_list,
            product_add,
            product_edit,
            product_active_inactive,
            //Service Type
            service_type_list,
            service_type_add,
            service_type_edit,
            service_type_active_inactive,
            //Services
            services_list,
            services_add,
            services_edit,
            services_active_inactive,
            //samaj notice
            samaj_notice_list,
            samaj_notice_add,
            samaj_notice_edit,
            samaj_notice_active_inactive,
            //App user
            app_user_list,
            app_user_add,
            app_user_edit,
            app_user_active_inactive,
        } = this.props.userData.permissions;
        console.log(this.props.history);
        this.props.history.listen((location, action) => {
            console.log(location.pathname);
            console.log(ProjectRoutes.area.country.list.url);

            let flag = false;
            switch (location.pathname) {
                case ProjectRoutes.area.country.list.url:
                case ProjectRoutes.area.state.list.url:
                case ProjectRoutes.area.city.list.url:
                    flag = !area_list;
                    break;
                case ProjectRoutes.area.country.add.url:
                case ProjectRoutes.area.state.add.url:
                case ProjectRoutes.area.city.add.url:
                    flag = !area_add;
                    break;
                case ProjectRoutes.area.country.update.url:
                case ProjectRoutes.area.state.update.url:
                case ProjectRoutes.area.city.update.url:
                    flag = !area_edit;
                    break;
            }
            if (flag) {
                alert('Permissions Required to access this module/screen! Please Contact Admin.');
                this.props.history.goBack();
            }
        })
    }


    render() {
        return null;
    }
}

export default withRouter(PermissionChecker);