import React from 'react';
import {Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../../../../common/pageTitle";
import {Link} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";

export default class StateAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            country_id: ''
        }
    }

    change = (event) => {
        this.setState({
            country_id: event.target.value
        });
    }

    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    }

    add = () => {
        if (!this.props.isRequesting) {
            this.props.addState(this.state.name, this.state.country_id);
        }
    }

    render() {
        const {countries, isRequesting} = this.props;

        const {name} = this.state;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Hindroot State"
                    subheading="Add a new state for Hindroot business."
                    icon="pe-7s-map-marker icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.area.state.list.url }>
                            <Button className="btn-shadow mr-3" color="dark">List State</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="6" md={{size: 6, offset: 3}}>
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>Add State</CardTitle>
                                    <Form>
                                        <FormGroup>
                                            <Label for="coutry">Select Country</Label>
                                            <Input type="select" name="select" id="country" onChange={this.change}>
                                                {countries && countries.length > 0 && countries.map((item, index) => {
                                                    return (
                                                        <option key={"" + index} value={item.id ? item.id : ''}>{item.name}</option>
                                                    )
                                                })}
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="name">Name</Label>
                                            <Input type="text" name="name" id="name" placeholder="pick a name..." value={name} onChange={this.onNameChange}/>
                                            <FormFeedback>Oh noes! pick a correct name for country</FormFeedback>
                                        </FormGroup>
                                        <Button color="primary" onClick={this.add}>
                                            {isRequesting && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                            {isRequesting ? "Adding..." : "Add"}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
