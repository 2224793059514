import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import RatingAdd from "./add";
import RatingList from "./list";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class Rating extends React.Component {

    list = (props) => (
        <RatingList {...props}
                    getRating={this.props.getRating} deleteRating={this.props.deleteRating}
                    ratings={this.props.ratings} totalCount={this.props.totalCount} updateRatingStatus={this.props.updateRatingStatus}
                    isRequestingForWhom={this.props.isRequestingForWhom}/>
    );
    add = (props) => (
        <RatingAdd {...props} addRating={this.props.addRating} isRequesting={this.props.isRequestingForWhom === 'sale/rating/add'}/>
    );
    update = (props) => (
        <RatingAdd {...props} addRating={this.props.addRating} isRequesting={this.props.isRequestingForWhom === 'sale/rating/add'}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.rating.url} render={() => (
                    <Redirect to={ProjectRoutes.rating.list.url }/>
                )}/>
                <Route path={ProjectRoutes.rating.list.url} render={this.list}/>
                <Route path={ProjectRoutes.rating.add.url} render={this.add}/>
                <Route path={ProjectRoutes.rating.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
