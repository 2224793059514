import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import Hamburger from 'react-hamburgers';
import images from "../../../../assets/images";
import AppMobileMenu from '../appMobileMenu';
import * as actions from "../actions";
import * as constants from "../constants";

class HeaderLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false
        };
    }

    toggleEnableClosedSidebar = () => {
        let {enableClosedSidebar, setEnableClosedSidebar} = this.props;
        setEnableClosedSidebar(!enableClosedSidebar);
    }

    state = {
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false,
    };

    render() {
        let {
            enableClosedSidebar,
        } = this.props;

        const {
        } = this.state;

        return (
            <Fragment>
                <div className="app-header__logo">
                    {/*<div className="d-flex justify-content-center flex-grow-1">
                        <img src={images.logo} width={90} alt="...logo"/>
                    </div>*/}
                    <div className="logo-src">
                        <img src={images.logo} width={50} alt="...logo"/>
                    </div>
                    <div className="header__pane ml-auto">
                        <div onClick={this.toggleEnableClosedSidebar}>
                            <Hamburger
                                active={enableClosedSidebar}
                                type="elastic"
                                onClick={() => this.setState({active: !this.state.active})}
                            />
                        </div>
                    </div>
                </div>
                <AppMobileMenu/>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    enableClosedSidebar: state[constants.NAME].enableClosedSidebar,
    enableMobileMenu: state[constants.NAME].enableMobileMenu,
    enableMobileMenuSmall: state[constants.NAME].enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({
    setEnableClosedSidebar: enable => dispatch(actions.setEnableClosedSidebar(enable)),
    setEnableMobileMenu: enable => dispatch(actions.setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: enable => dispatch(actions.setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);