import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import Brand from "./components";
import common from "../../../common";

class BrandSmart extends React.Component {

    componentDidMount() {
        this.props.callOnPageLoad(this.props.match.path);
    }

    render() {
        return (
            <Brand {...this.props}/>
        )
    }
}

const mapStateToProps = state => ({
    brands: state[constants.NAME].brands,
    isRequestingForWhom: state[common.constants.NAME].isRequestingForWhom,
});

const mapDispatchToProps = dispatch => {
    return {
        callOnPageLoad: (page_url) => dispatch(actions.callOnPageLoad(page_url)),
        getBrand: () => dispatch(actions.getBrand()),
        addBrand: (name, id) => dispatch(actions.addBrand(name, id)),
        updateBrandStatus: (id, status, name) => dispatch(actions.updateBrandStatus(id, status, name)),
    }
};

export const BrandContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandSmart));
export default {actionTypes, actions, reducer, constants};