import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import CityAdd from "./add";
import CityList from "./list";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import CityUpdate from "./update";

export default class City extends React.Component {

    list = (props) => (
        <CityList {...props} getCity={this.props.getCity} getState={this.props.getState} countries={this.props.countries} states={this.props.states} cities={this.props.cities}
                  updateCityStatus={this.props.updateCityStatus}
                  isRequestingForWhom={this.props.isRequestingForWhom}/>
    );

    add = (props) => (
        <CityAdd {...props} addCity={this.props.addCity} getState={this.props.getState} states={this.props.states} countries={this.props.countries}
                 isRequesting={this.props.isRequestingForWhom === 'city/add'} isRequestingForWhom={this.props.isRequestingForWhom}/>
    );

    update = (props) => (
        <CityUpdate {...props} addCity={this.props.addCity} isRequesting={this.props.isRequestingForWhom === 'city/add'}/>
    );

    render() {
        return (
            <Fragment>
                <Route exact path={ProjectRoutes.area.city.url} render={() => (
                    <Redirect to={ProjectRoutes.area.city.list.url }/>
                )}/>
                <Route path={ProjectRoutes.area.city.list.url} render={this.list}/>
                <Route path={ProjectRoutes.area.city.add.url} render={this.add}/>
                <Route path={ProjectRoutes.area.city.update.url} render={this.update}/>
            </Fragment>
        )
    }
}
