/*
 * This is a common place where application state resides known as store
 * combineReducer() fuction is used to merge more than one reducer into a single unit
 * applyMiddleware() function is used to apply middleware for mid task i.e. Web service calling
 * createStore() method return store created with the help of reducers and middlewares
 * */

import Reactotron from "reactotron-react-js";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import fetchMiddleware from "../middleware";
import common from "../modules/common";
import city from "../modules/screens/area/city";
import country from "../modules/screens/area/country";
import state from "../modules/screens/area/state";
import dashboard from "../modules/screens/dashboard";
import department from "../modules/screens/department";
import designation from "../modules/screens/designation";
import employee from "../modules/screens/employee";
import customer from "../modules/screens/customer";
import category from "../modules/screens/sale/category";
import group from "../modules/screens/sale/group";
import brand from "../modules/screens/sale/brand";
import product from "../modules/screens/sale/product";
import productType from "../modules/screens/sale/productType";
import user from "../modules/screens/user";
import banner from "../modules/screens/banner";
import rating from "../modules/screens/rating";
import contactUs from "../modules/screens/contactUs";

const reducers = combineReducers({
    [common.constants.NAME]: common.reducer,
    [user.constants.NAME]: user.reducer,
    [dashboard.constants.NAME]: dashboard.reducer,
    [country.constants.NAME]: country.reducer,
    [state.constants.NAME]: state.reducer,
    [city.constants.NAME]: city.reducer,
    [designation.constants.NAME]: designation.reducer,
    [department.constants.NAME]: department.reducer,
    [group.constants.NAME]: group.reducer,
    [brand.constants.NAME]: brand.reducer,
    [banner.constants.NAME]: banner.reducer,
    [rating.constants.NAME]: rating.reducer,
    [contactUs.constants.NAME]: contactUs.reducer,
    [category.constants.NAME]: category.reducer,
    [productType.constants.NAME]: productType.reducer,
    [product.constants.NAME]: product.reducer,
    [employee.constants.NAME]: employee.reducer,
    [customer.constants.NAME]: customer.reducer,
});

/**
 * This root reducer will be used if we want a specific action to change all reducers state at once
 * @param state
 * @param action
 * @returns {any}
 */
const rootReducer = (state, action) => {
    return reducers(state, action)
}

let store = null;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    store = Reactotron.createStore(rootReducer, compose(applyMiddleware(thunk, fetchMiddleware,)));
} else {
    store = createStore(rootReducer, compose(applyMiddleware(thunk, fetchMiddleware,)));
}
export default store;