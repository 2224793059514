import React from 'react';
import {Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label, Row, Table, UncontrolledTooltip} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faThumbsDown, faThumbsUp, faTrash} from "@fortawesome/free-solid-svg-icons";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import PageTitle from "../../../common/pageTitle";
import DateHelper from "../../../../../utils/DateHelper";
import Constants from "../../../../../utils/Constants";
import $ from 'jquery';

export default class RatingList extends React.Component {

    constructor(props) {
        super(props);
        const queryObj = {
            product_id: HelperFunctions.getQueryStringValue('product_id') || '',
            user_id: HelperFunctions.getQueryStringValue('user_id') || '',
        }
        this.state = {
            data: {
                comment: '',
                product_id: '',
                user_id: '',
                rating: '',
                rating_type: '=',
                ...queryObj
            },
            currentPage: 1
        }
    }

    componentDidMount() {
        this.props.getRating(true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: this.state.currentPage});
        window.userCollapse();

        // if there are matching query params open filter by default
        if((this.state.data.user_id || this.state.data.product_id)){
            $('.collapsible').trigger('click')
        }
    }

    paginationClick = (pageNum) => {
        this.props.getRating(true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: pageNum});
        this.setState({currentPage: pageNum});
    };

    paginationSelect = (event) => {
        this.paginationClick(event.target.value)
    }

    renderPagination = () => {
        const {totalCount} = this.props;
        const {currentPage} = this.state;
        if (totalCount > Constants.defaultPageSize) {
            const numPages = Math.ceil(totalCount / Constants.defaultPageSize);

            const options = [];
            for (let i = 0; i < numPages; i++) {
                options.push(
                    <option key={"" + i} value={i + 1}>{'Page ' + (i + 1)}</option>
                );
            }

            const isPrevDisabled = currentPage === 1;
            const isNextDisabled = currentPage === numPages;

            return (
                <nav className="py-2" aria-label="Page navigation">
                    <ul className="pagination flex-wrap justify-content-end justify-content-lg-end mb-0">
                        <li className={`page-item mx-1 ${isPrevDisabled && "disabled"}`}>
                            <a className="page-link py-1 pg-link-height" onClick={this.paginationClick.bind(this, (currentPage - 1))} aria-label="Previous">
                                <span aria-hidden="true">«</span>
                            </a>
                        </li>
                        <Input type="select" name="pages" id="pages" bsSize="sm" className="pg-select-width"
                               onChange={this.paginationSelect} value={currentPage}>
                            {options}
                        </Input>
                        <li className={`page-item mx-1 ${isNextDisabled && "disabled"}`}>
                            <a className="page-link py-1 pg-link-height" onClick={this.paginationClick.bind(this, (currentPage + 1))} aria-label="Next">
                                <span aria-hidden="true">»</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            );
        } else return null;
    };

    reset = () => {
        this.setState({
            data: {
                comment: '',
                product_id: '',
                user_id: '',
                rating: '',
                rating_type: '=',
            }
        });
    };

    search = () => {
        if (!(this.props.isRequestingForWhom === "sale/rating/search")) {
            this.props.getRating(true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: 1}, "sale/rating/search");
            this.setState({currentPage: 1});
        }
    };

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };

    renderFilter = () => {
        const {isRequestingForWhom, brands} = this.props;
        const {data} = this.state;
        return (
            <React.Fragment>
                <button className="collapsible py-2 text-muted">Looking for something? <span className="text-success">Click here</span> to
                    refine your search.
                </button>
                <div className="content bg-light mb-3">
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="product_id">Product ID</Label>
                                    <Input type="text" name="product_id" id="product_id" bsSize="sm" value={data["product_id"]}
                                           onChange={this.onInputChange.bind(this, "product_id")}/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="user_id">User Id</Label>
                                    <Input type="text" name="user_id" id="user_id" bsSize="sm" value={data["user_id"]}
                                           onChange={this.onInputChange.bind(this, "user_id")}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form className="pt-2">
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="comment">Comment</Label>
                                    <Input type="text" name="comment" id="comment" bsSize="sm" value={data["comment"]}
                                           onChange={this.onInputChange.bind(this, "comment")}/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="rating">Rating</Label>
                                    <Row>
                                        <Col md={4}>
                                            <Input type="select" name="rating_type" id="rating_type" bsSize="sm"
                                                   value={data["rating_type"]}
                                                   onChange={this.onInputChange.bind(this, "rating_type")}>
                                                <option value="=">{'= equal to'}</option>
                                                <option value=">">{'> More Than'}</option>
                                                <option value="<">{'< Less Than'}</option>
                                            </Input>
                                        </Col>
                                        <Col md={8}>
                                            <Input type="number" name="rating" id="rating" bsSize="sm" value={data["rating"]}
                                                   onChange={this.onInputChange.bind(this, "rating")}/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button color="primary" onClick={this.search} className="mb-3">
                            {(this.props.isRequestingForWhom === "sale/rating/search") &&
                                <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                            {(this.props.isRequestingForWhom === "sale/rating/search") ? "Searching..." : "Search"}
                        </Button>
                        <Button color="secondary" onClick={this.reset} className="mb-3 ml-2">
                            Reset
                        </Button>
                    </Form>
                </div>
            </React.Fragment>
        );
    };

    render() {
        const {ratings, isRequestingForWhom, updateRatingStatus, deleteRating} = this.props;
        const query = {...this.state.data, page_size: Constants.defaultPageSize, page_number: this.state.currentPage};
        return (
            <React.Fragment>
                <PageTitle
                    heading="Hindroot ratings"
                    subheading="Available ratings for Hindroot."
                    icon="pe-7s-star icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.rating.add.url }>
                            <Button className="btn-shadow mr-3" color="dark">Add rating</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>Rating List</CardTitle>
                                    {this.renderFilter()}
                                    {ratings && ratings.length > 0 ? (
                                        <React.Fragment>
                                            {this.renderPagination()}
                                            <Table responsive striped className="mb-0">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Product</th>
                                                    <th>User</th>
                                                    <th>Date</th>
                                                    <th>Rating/Comment</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {ratings.map((item, index) => {
                                                    const obj = {};
                                                    if (HelperFunctions.isActive(item.status)) {
                                                        obj.icon = faThumbsDown;
                                                        obj.ttxt = "Unapprove Rating/Comment";
                                                        obj.color = "danger";
                                                        obj.statusToChange = "Inactive";
                                                    } else {
                                                        obj.icon = faThumbsUp;
                                                        obj.ttxt = "Approve Rating/Comment";
                                                        obj.color = "success";
                                                        obj.statusToChange = "Active";
                                                    }
                                                    return (
                                                        <tr key={"" + index}>
                                                            <td>{(((this.state.currentPage - 1) * Constants.defaultPageSize) + index) + 1}</td>
                                                            <td>{item.product_name}</td>
                                                            <td>{item.user_name}</td>
                                                            <td>{DateHelper.getDisplayDateFromStamp(item.added_date)}</td>
                                                            <td><b>{item.rating}</b>{`/(${item.comment})`}</td>
                                                            <td>{item.status}</td>
                                                            <td>
                                                                <Link to={{
                                                                    pathname: ProjectRoutes.rating.update.url,

                                                                    state: {rating: item}
                                                                }}>
                                                                    <Button className="btn-shadow mr-2 mt-1 btn-sm" color="info">
                                                                        <FontAwesomeIcon icon={faPen} size={"1x"}/>
                                                                    </Button>
                                                                </Link>
                                                                <Button id={"btn" + index} className="btn-shadow mt-1 mr-2 btn-sm" color={obj.color}
                                                                        onClick={updateRatingStatus.bind(this, {...item, status: obj.statusToChange}, query)}>
                                                                    <FontAwesomeIcon icon={obj.icon} size={"1x"}/>
                                                                    <UncontrolledTooltip placement="left" target={"btn" + index}>
                                                                        {obj.ttxt}
                                                                    </UncontrolledTooltip>
                                                                </Button>
                                                                <Button id={"btndel" + index} className="btn-shadow mt-1 mr-2 btn-sm" color={'danger'}
                                                                        onClick={deleteRating.bind(this, item.id, query)}>
                                                                    <FontAwesomeIcon icon={faTrash} size={"1x"}/>
                                                                    <UncontrolledTooltip placement="left" target={"btndel" + index}>
                                                                        {'Delete Rating'}
                                                                    </UncontrolledTooltip>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                            {this.renderPagination()}
                                        </React.Fragment>
                                    ) : ratings && ratings.message ? (
                                        <div className="page-title-subheading d-flex justify-content-center">No ratings found.</div>
                                    ) : (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                                {isRequestingForWhom === "sale/rating/update-status" &&
                                    <div className="d-flex justify-content-center align-items-center loader-absolute">
                                        <div className="spinner-border mt-4 mb-4 text-primary" role="status">
                                        </div>
                                    </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
