import React from "react";
import {Redirect} from "react-router-dom";
import ErrorContainer from "../../commons/components/errorContainer/index";
import HelperFunctions from "../../utils/HelperFunctions";
import ProjectRoutes from "./projectRoutes";
import {UserContainer} from "../../redux/modules/screens/user";
import {DashboardContainer} from "../../redux/modules/screens/dashboard";
import {CountryContainer} from "../../redux/modules/screens/area/country";
import {StateContainer} from "../../redux/modules/screens/area/state";
import {CityContainer} from "../../redux/modules/screens/area/city";
import {EmployeeContainer} from "../../redux/modules/screens/employee";
import {CustomerContainer} from "../../redux/modules/screens/customer";
import {DesignationContainer} from "../../redux/modules/screens/designation";
import {DepartmentContainer} from "../../redux/modules/screens/department";
import {GroupContainer} from "../../redux/modules/screens/sale/group";
import {BrandContainer} from "../../redux/modules/screens/sale/brand";
import {CategoryContainer} from "../../redux/modules/screens/sale/category";
import {ProductTypeContainer} from "../../redux/modules/screens/sale/productType";
import {ProductContainer} from "../../redux/modules/screens/sale/product";
import {BannerContainer} from "../../redux/modules/screens/banner";
import {RatingContainer} from "../../redux/modules/screens/rating";
import {ContactUsContainer} from "../../redux/modules/screens/contactUs";

const routes = [
    {
        path: ProjectRoutes.base.url,
        exact: true,
        render: () => (
            <Redirect to={ProjectRoutes.user.url}/>
        )
    },
    {
        path: ProjectRoutes.user.url,
        component: UserContainer,
    },
    {
        path: ProjectRoutes.dashboard.url,
        component: DashboardContainer,
        exact: true
    },
    {
        path: ProjectRoutes.area.country.url,
        component: CountryContainer,
    },
    {
        path: ProjectRoutes.area.state.url,
        component: StateContainer,
    },
    {
        path: ProjectRoutes.area.city.url,
        component: CityContainer,
    },
    {
        path: ProjectRoutes.employee.url,
        component: EmployeeContainer,
    },
    {
        path: ProjectRoutes.customer.url,
        component: CustomerContainer,
    },
    {
        path: ProjectRoutes.designation.url,
        component: DesignationContainer,
    },
    {
        path: ProjectRoutes.department.url,
        component: DepartmentContainer,
    },
    {
        path: ProjectRoutes.sale.group.url,
        component: GroupContainer,
    },
    {
        path: ProjectRoutes.sale.brand.url,
        component: BrandContainer,
    },
    {
        path: ProjectRoutes.banner.url,
        component: BannerContainer,
    },
    {
        path: ProjectRoutes.rating.url,
        component: RatingContainer,
    },
    {
        path: ProjectRoutes.contactUs.url,
        component: ContactUsContainer,
    },
    {
        path: ProjectRoutes.sale.category.url,
        component: CategoryContainer,
    },
    {
        path: ProjectRoutes.sale.productType.url,
        component: ProductTypeContainer,
    },
    {
        path: ProjectRoutes.sale.product.url,
        component: ProductContainer,
    },
    {
        component: ErrorContainer,
    },

];

export default routes;