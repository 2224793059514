import {faAngleDown} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

import {DropdownMenu, DropdownToggle, Nav, NavItem, UncontrolledButtonDropdown} from 'reactstrap';
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
    }

    logout = () => {
        this.props.logout(this.props.history);
    };

    render() {
        const {first_name, last_name, designation_name, base_url, image_url} = this.props.userData;
        return (
            <Fragment>
                <div className="header-btn-lg pr-0">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="link" className="p-0">
                                        <img width={42} height={42} className="rounded-circle" src={base_url + image_url} alt=""/>
                                        <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown}/>
                                    </DropdownToggle>
                                    <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                        <Nav vertical>
                                            <NavItem className="nav-item-header">
                                                Activity
                                            </NavItem>
                                            <NavItem>
                                                <Link to={ProjectRoutes.user.otp.url } className={"nav-link"}>
                                                    Change Password
                                                </Link>
                                            </NavItem>
                                            <NavItem className="nav-item-header">
                                                My Account
                                            </NavItem>
                                            {/*<NavItem>
                                                <Link to={ProjectRoutes.employee.profile.url } className={"nav-link"}>
                                                    My Profile
                                                </Link>
                                            </NavItem>*/}
                                            <NavItem>
                                                <a className={"nav-link"} onClick={this.logout}>
                                                    Log Out
                                                </a>
                                            </NavItem>
                                        </Nav>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className="widget-content-left  ml-3 header-user-info">
                                <div className="widget-heading">
                                    {first_name + " " + last_name}
                                </div>
                                <div className="widget-subheading">
                                    {designation_name}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default UserBox;