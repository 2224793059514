import React from 'react';
import {Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label, Row, Table, UncontrolledTooltip} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faThumbsDown, faThumbsUp, faTrash} from "@fortawesome/free-solid-svg-icons";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import PageTitle from "../../../common/pageTitle";
import DateHelper from "../../../../../utils/DateHelper";
import Constants from "../../../../../utils/Constants";
import $ from 'jquery';

export default class ContactUsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: '',
                email: '',
                mobile: '',
                contactus_id: ''
            },
            currentPage: 1
        }
    }

    componentDidMount() {
        this.props.getContactUs(true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: this.state.currentPage});
        window.userCollapse();
    }

    paginationClick = (pageNum) => {
        this.props.getContactUs(true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: pageNum});
        this.setState({currentPage: pageNum});
    };

    paginationSelect = (event) => {
        this.paginationClick(event.target.value)
    }

    renderPagination = () => {
        const {totalCount} = this.props;
        const {currentPage} = this.state;
        if (totalCount > Constants.defaultPageSize) {
            const numPages = Math.ceil(totalCount / Constants.defaultPageSize);

            const options = [];
            for (let i = 0; i < numPages; i++) {
                options.push(
                    <option key={"" + i} value={i + 1}>{'Page ' + (i + 1)}</option>
                );
            }

            const isPrevDisabled = currentPage === 1;
            const isNextDisabled = currentPage === numPages;

            return (
                <nav className="py-2" aria-label="Page navigation">
                    <ul className="pagination flex-wrap justify-content-end justify-content-lg-end mb-0">
                        <li className={`page-item mx-1 ${isPrevDisabled && "disabled"}`}>
                            <a className="page-link py-1 pg-link-height" onClick={this.paginationClick.bind(this, (currentPage - 1))} aria-label="Previous">
                                <span aria-hidden="true">«</span>
                            </a>
                        </li>
                        <Input type="select" name="pages" id="pages" bsSize="sm" className="pg-select-width"
                               onChange={this.paginationSelect} value={currentPage}>
                            {options}
                        </Input>
                        <li className={`page-item mx-1 ${isNextDisabled && "disabled"}`}>
                            <a className="page-link py-1 pg-link-height" onClick={this.paginationClick.bind(this, (currentPage + 1))} aria-label="Next">
                                <span aria-hidden="true">»</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            );
        } else return null;
    };

    reset = () => {
        this.setState({
            data: {
                name: '',
                email: '',
                mobile: '',
                contactus_id: ''
            }
        });
    };

    search = () => {
        if (!(this.props.isRequestingForWhom === "sale/contactUs/search")) {
            this.props.getContactUs(true, {...this.state.data, page_size: Constants.defaultPageSize, page_number: 1}, "sale/contactUs/search");
            this.setState({currentPage: 1});
        }
    };

    onInputChange = (key, event) => {
        const dataCopy = HelperFunctions.copyObject(this.state.data);
        dataCopy[key] = event.target.value;
        this.setState({
            data: dataCopy
        });
    };

    renderFilter = () => {
        const {isRequestingForWhom, brands} = this.props;
        const {data} = this.state;
        return (
            <React.Fragment>
                <button className="collapsible py-2 text-muted">Looking for something? <span className="text-success">Click here</span> to
                    refine your search.
                </button>
                <div className="content bg-light mb-3">
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="contactus_id">Query ID</Label>
                                    <Input type="text" name="contactus_id" id="contactus_id" bsSize="sm" value={data["contactus_id"]}
                                           onChange={this.onInputChange.bind(this, "contactus_id")}/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="name">Name</Label>
                                    <Input type="text" name="name" id="name" bsSize="sm" value={data["name"]}
                                           onChange={this.onInputChange.bind(this, "name")}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form className="pt-2">
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="email">email</Label>
                                    <Input type="text" name="email" id="email" bsSize="sm" value={data["email"]}
                                           onChange={this.onInputChange.bind(this, "email")}/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="mobile">mobile</Label>
                                    <Input type="text" name="mobile" id="mobile" bsSize="sm" value={data["mobile"]}
                                           onChange={this.onInputChange.bind(this, "mobile")}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button color="primary" onClick={this.search} className="mb-3">
                            {(this.props.isRequestingForWhom === "sale/contactUs/search") &&
                                <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                            {(this.props.isRequestingForWhom === "sale/contactUs/search") ? "Searching..." : "Search"}
                        </Button>
                        <Button color="secondary" onClick={this.reset} className="mb-3 ml-2">
                            Reset
                        </Button>
                    </Form>
                </div>
            </React.Fragment>
        );
    };

    render() {
        const {contactUss, isRequestingForWhom, updateContactUsStatus, deleteContactUs} = this.props;
        const query = {...this.state.data, page_size: Constants.defaultPageSize, page_number: this.state.currentPage};
        return (
            <React.Fragment>
                <PageTitle
                    heading="Hindroot contactUss"
                    subheading="Available contactUss for Hindroot."
                    icon="pe-7s-headphones icon-gradient bg-happy-itmeo"
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>ContactUs List</CardTitle>
                                    {this.renderFilter()}
                                    {contactUss && contactUss.length > 0 ? (
                                        <React.Fragment>
                                            {this.renderPagination()}
                                            <Table responsive striped className="mb-0">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Query ID</th>
                                                    <th>Name/Email/Mobile</th>
                                                    <th>Subject/Comment</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {contactUss.map((item, index) => {
                                                    const obj = {};
                                                    if (HelperFunctions.isActive(item.status)) {
                                                        obj.icon = faThumbsUp;
                                                        obj.ttxt = "Mark Query Resolved";
                                                        obj.color = "success";
                                                        obj.statusToChange = "Inactive";
                                                    } else {
                                                        obj.icon = faThumbsDown;
                                                        obj.ttxt = "Mark Query UnResolved";
                                                        obj.color = "danger";
                                                        obj.statusToChange = "Active";
                                                    }
                                                    return (
                                                        <tr key={"" + index}>
                                                            <td>{(((this.state.currentPage - 1) * Constants.defaultPageSize) + index) + 1}</td>
                                                            <td>{item.id}</td>
                                                            <td>
                                                                {item.name}
                                                                <br/>
                                                                {item.email}
                                                                <br/>
                                                                {item.mobile}
                                                            </td>
                                                            <td>
                                                                <i>{item.subject}</i>
                                                                <br/>
                                                                {item.comment}</td>
                                                            <td>{DateHelper.getDisplayDateFromStamp(item.added_date)}</td>
                                                            <td>{item.status === 'Active' ? 'UnResolved': 'Resolved'}</td>
                                                            <td>
                                                                <Button id={"btn" + index} className="btn-shadow mt-1 mr-2 btn-sm" color={obj.color}
                                                                        onClick={updateContactUsStatus.bind(this, {...item, status: obj.statusToChange}, query)}>
                                                                    <FontAwesomeIcon icon={obj.icon} size={"1x"}/>
                                                                    <UncontrolledTooltip placement="left" target={"btn" + index}>
                                                                        {obj.ttxt}
                                                                    </UncontrolledTooltip>
                                                                </Button>
                                                                <Button id={"btndel" + index} className="btn-shadow mt-1 mr-2 btn-sm" color={'danger'}
                                                                        onClick={deleteContactUs.bind(this, item.id, query)}>
                                                                    <FontAwesomeIcon icon={faTrash} size={"1x"}/>
                                                                    <UncontrolledTooltip placement="left" target={"btndel" + index}>
                                                                        {'Delete This Query'}
                                                                    </UncontrolledTooltip>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                            {this.renderPagination()}
                                        </React.Fragment>
                                    ) : contactUss && contactUss.message ? (
                                        <div className="page-title-subheading d-flex justify-content-center">No contactUss found.</div>
                                    ) : (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                                {isRequestingForWhom === "sale/contactUs/update-status" &&
                                    <div className="d-flex justify-content-center align-items-center loader-absolute">
                                        <div className="spinner-border mt-4 mb-4 text-primary" role="status">
                                        </div>
                                    </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
