import React from "react";
import {Redirect, Route} from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";
import Login from "./login";
import RecoverPassword from "./recoverPassword";
import OTP from "./otp";
import NewPassword from "./newPassword";


export default class User extends React.Component {
    render() {
        const {userData, login, isRequestingForWhom, forgotPassword, forgotPassData, matchOTPData, matchOTP, newPasswordData, newPassword} = this.props;
        return (
            <div className="app-container login-absolute">
                <div className="h-100 bg-amy-crisp bg-animation">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Route exact path={ProjectRoutes.user.url} render={() => (
                            <Redirect to={ProjectRoutes.user.login.url}/>
                        )}/>
                        <Route path={ProjectRoutes.user.login.url} render={(props) => (
                            <Login {...props} userData={userData} login={login}
                                   isRequestingForWhom={isRequestingForWhom}/>
                        )}/>
                        <Route path={ProjectRoutes.user.recoverPassword.url} render={(props) => (
                            <RecoverPassword {...props} forgotPassData={forgotPassData} forgotPassword={forgotPassword}
                                             isRequesting={isRequestingForWhom === "user/forgot-password"}/>
                        )}/>
                        <Route path={ProjectRoutes.user.otp.url} render={(props) => (
                            <OTP {...props} matchOTPData={matchOTPData} matchOTP={matchOTP}
                                 isRequesting={isRequestingForWhom === "user/match-otp"}/>
                        )}/>
                        <Route path={ProjectRoutes.user.newPassword.url} render={(props) => (
                            <NewPassword {...props} newPasswordData={newPasswordData} newPassword={newPassword}
                                         isRequesting={isRequestingForWhom === "user/new-password"}/>
                        )}/>
                    </div>
                </div>
            </div>
        )
    }
}
