import React from 'react';
import {Button, Card, CardBody, Col, Input, Row, Table, UncontrolledTooltip} from 'reactstrap';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {Link} from "react-router-dom";
import ProjectRoutes from "../../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../../utils/HelperFunctions";
import PageTitle from "../../../../common/pageTitle";
import {faPen, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class CityList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            selectedCountryNumber: 0,
            selectedStateNumber: 0
        }
    }

    componentDidMount() {
        this.props.getCity('', '');
    }

    countryChange = (event) => {
        this.props.getCity('', '');
        this.props.getState(this.props.countries[event.target.value].id);
        this.setState({selectedCountryNumber: event.target.value})
    }
    stateChange = (event) => {
        this.props.getCity(this.props.countries[this.state.selectedCountryNumber].id, this.props.states[event.target.value].id);
        this.setState({selectedStateNumber: event.target.value})
    }

    render() {
        const {countries, states, cities, isRequestingForWhom, updateCityStatus} = this.props;
        const citiesLength = cities ? cities.length : null;
        const {selectedCountryNumber, selectedStateNumber} = this.state;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Hindroot City"
                    subheading="Available City for Hindroot."
                    icon="pe-7s-map-marker icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.area.city.add.url }>
                            <Button className="btn-shadow mr-3" color="dark">Add City</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <div className="card-header">
                                        City List
                                        <div className="btn-actions-pane-right">
                                            <Row form>
                                                <Col>
                                                    <Input type="select" name="country" id="country" bsSize="sm" onChange={this.countryChange}>
                                                        {countries && countries.length > 0 && countries.map((item, index) => {
                                                            return (
                                                                <option key={"" + index} value={index}>{item.name}</option>
                                                            )
                                                        })}
                                                    </Input>
                                                    {isRequestingForWhom === 'country/list' && <div className="d-flex justify-content-center align-items-center loader-absolute background-none">
                                                        <div className="spinner-border spinner-border-sm text-primary" role="status"/>
                                                    </div>}
                                                </Col>
                                                <Col>
                                                    <Input type="select" name="state" id="state" bsSize="sm" onChange={this.stateChange}>
                                                        {states && states.length > 0 && states.map((item, index) => {
                                                            return (
                                                                <option key={"" + index} value={index}>{item.name}</option>
                                                            )
                                                        })}
                                                    </Input>
                                                    {isRequestingForWhom === 'state/list' && <div className="d-flex justify-content-center align-items-center loader-absolute background-none">
                                                        <div className="spinner-border spinner-border-sm text-primary" role="status"/>
                                                    </div>}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    {citiesLength > 0 && (
                                        <Table responsive striped className="mb-0">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {cities.map((item, index) => {
                                                const obj = {};
                                                if (HelperFunctions.isActive(item.status)) {
                                                    obj.icon = faThumbsDown;
                                                    obj.ttxt = "Make Inactive";
                                                    obj.color = "danger";
                                                    obj.statusToChange = "Inactive";
                                                } else {
                                                    obj.icon = faThumbsUp;
                                                    obj.ttxt = "Make Active";
                                                    obj.color = "success";
                                                    obj.statusToChange = "Active";
                                                }
                                                return (
                                                    <tr key={"" + index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.name}</td>
                                                        <td>{item.status}</td>
                                                        <td>
                                                            <Link to={{
                                                                pathname: ProjectRoutes.area.city.update.url,

                                                                state: {
                                                                    id: item.id,
                                                                    country: this.props.countries[selectedCountryNumber],
                                                                    state: this.props.states[selectedStateNumber],
                                                                    name: item.name
                                                                }
                                                            }}>
                                                                <Button className="btn-shadow mr-2 btn-sm" color="info">
                                                                    <FontAwesomeIcon icon={faPen} size={"1x"}/>
                                                                </Button>
                                                            </Link>
                                                            <Button id={"btn" + index} className="btn-shadow mr-2 btn-sm" color={obj.color}
                                                                    onClick={updateCityStatus.bind(this, this.props.countries[selectedCountryNumber].id, this.props.states[selectedStateNumber].id, item.id, obj.statusToChange)}>
                                                                <FontAwesomeIcon icon={obj.icon} size={"1x"}/>
                                                                <UncontrolledTooltip placement="left" target={"btn" + index}>
                                                                    {obj.ttxt}
                                                                </UncontrolledTooltip>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </Table>
                                    )}
                                    {citiesLength === 0 && (
                                        <div className="page-title-subheading d-flex justify-content-center">No City found for selected country/state!</div>
                                    )}
                                    {isRequestingForWhom === 'city/list' && (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-grow mt-4 mb-4 text-primary" role="status">
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                                {isRequestingForWhom === "city/update-status" &&
                                <div className="d-flex justify-content-center align-items-center loader-absolute">
                                    <div className="spinner-border mt-4 mb-4 text-primary" role="status">
                                    </div>
                                </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
