import React from 'react';
import {Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../../../common/pageTitle";
import {Link} from "react-router-dom";
import ProjectRoutes from "../../../../../config/routes/projectRoutes";
import HelperFunctions from "../../../../../utils/HelperFunctions";

export default class DesignationAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
        }
    }

    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        });
    }

    add = () => {
        if (!this.props.isRequesting) {
            this.props.addDesignation(this.state.name);
        }
    }

    render() {
        const {name} = this.state;
        const {isRequesting} = this.props;

        return (
            <React.Fragment>
                <PageTitle
                    heading="Hindroot Designations"
                    subheading="Add a new designation for Hindroot business."
                    icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
                    actionChildren={(
                        <Link to={ProjectRoutes.designation.list.url }>
                            <Button className="btn-shadow mr-3" color="dark">List Designation</Button>
                        </Link>
                    )}
                />
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Row>
                        <Col lg="6" md={{size: 6, offset: 3}}>
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>Add Designation</CardTitle>
                                    <Form>
                                        <FormGroup>
                                            <Label for="name">Designation</Label>
                                            <Input type="text" name="name" id="name" placeholder="pick a designation..." value={name} onChange={this.onNameChange}/>
                                            <FormFeedback>Oh noes! pick a correct name for designation</FormFeedback>
                                        </FormGroup>
                                        <Button color="primary" onClick={this.add}>
                                            {isRequesting && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"/>}
                                            {isRequesting ? "Adding..." : "Add"}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </React.Fragment>
        );
    }
}
